import { Component } from "react";
import {
  createTheme,
  MuiThemeProvider,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { appStore } from "../../store";
import { YieldApi } from "../../../api/yield";
import { toast } from "react-toastify";
import { diff } from "deep-object-diff";
export interface IEditRoomTypePanelProps {
  isNew: boolean;
  roomType: any;
  hotel: any;
  onclose: () => void;
  onUpdate: (rt: any) => void;
  unEditable: boolean;
  allData: any;
  counter: number;
}

interface State {
  data: any;
  validatesRoomTypeName: boolean;
  validatesRoomTypeNameMsg: any;
  validateid: boolean;
  validateidMsg: any;
  validateminRate: boolean;
  validateminRateMsg: any;
  validatemaxRate: boolean;
  validatemaxRateMsg: any;
  validateoccupancy: boolean;
  validateoccupancyMsg: any;
  validatechildOccupancy: boolean;
  validatechildOccupancyMsg: any;
  validateinventory: boolean;
  validateinventoryMsg: any;
  validateformula: boolean;
  validateformulaMsg: any;
  disableButton: boolean;
}

const theme = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        marginLeft: 25,
        width: 230,
      },
    },
    MuiCard: {
      root: {
        width: "30%",
        float: "left",
        marginLeft: "10px",
        marginTop: "10px",
        fontSize: "1em",
      },
    },
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
  },
});
class EditRoomPanel extends Component<IEditRoomTypePanelProps> {
  readonly state: State = {
    data: {
      shortDescription: "",
      id: "",
      minRate: "",
      maxRate: "",
      occupancy: "",
      childOccupancy: "",
      inventory: "",
      formula: "",
      sortOrder: 0,
    },
    validatesRoomTypeName: false,
    validatesRoomTypeNameMsg: "",
    validateid: false,
    validateidMsg: "",
    validateminRate: false,
    validateminRateMsg: "",
    validatemaxRate: false,
    validatemaxRateMsg: "",
    validateoccupancy: false,
    validateoccupancyMsg: "",
    validatechildOccupancy: false,
    validatechildOccupancyMsg: "",
    validateinventory: false,
    validateinventoryMsg: "",
    validateformula: false,
    validateformulaMsg: "",
    disableButton: false,
  };
  allData: any;

  constructor(props: any) {
    super(props);
    this.state.data = this.props.roomType;
    this.allData = this.props.allData;
  }

  handleChange = (event: any) => {
    let fieldName = event.target.name;
    let value = event.target.value || event.target.checked;
    let v: any;
    let data = this.state.data;
    console.log(this.allData);

    switch (fieldName) {
      default:
        v = value || "";
    }
    data[fieldName] = v;
    this.setState({ data });
  };

  createRoomType = async () => {
    let pattern = /^[0-9]*$/;
    let hotel = this.props.hotel;
    let rt = this.state.data;
    if (rt.isManual) {
      delete rt.formula;
    } else {
      delete rt.defaultRate;
    }
    let formulafirstTwoChars = this.state.data.formula.slice(0, 2);
    let FormulaCharPrefix = formulafirstTwoChars.toUpperCase();

    if (
      !this.state.data.shortDescription ||
      !this.state.data.id ||
      !this.state.data.minRate ||
      !this.state.data.maxRate ||
      Number(this.state.data.minRate) > Number(this.state.data.maxRate) ||
      Number(this.state.data.minRate) < 0 ||
      Number(this.state.data.minRate) < 0 ||
      !this.state.data.occupancy ||
      !this.state.data.inventory ||
      !this.state.data.formula ||
      !this.state.data.shortDescription.match(/^[a-zA-Z0-9\s\+]+$/) ||
      !this.state.data.id.match(/^[A-Za-z0-9\-\_\@]+$/) ||
      !pattern.test(this.state.data.minRate) ||
      !pattern.test(this.state.data.maxRate) ||
      !pattern.test(this.state.data.occupancy) ||
      !pattern.test(this.state.data.childOccupancy) ||
      !pattern.test(this.state.data.inventory) ||
      !this.state.data.formula.match(/^[a-zA-Z0-9()+\-*\/.]*$/) ||
      !(FormulaCharPrefix == "BR") ||
      parseInt(this.state.data.minRate) < 0
    ) {
      if (!this.state.data.shortDescription) {
        this.setState({ validatesRoomTypeName: true });
        this.setState({
          validatesRoomTypeNameMsg: "Mandatory field",
        });
      } else if (
        !this.state.data.shortDescription.match(/^[a-zA-Z0-9\s\+]+$/)
      ) {
        this.setState({ validatesRoomTypeName: true });
        this.setState({
          validatesRoomTypeNameMsg:
            "Only Alphabets, numbers, + and spaces allowed.",
        });
      } else {
        this.setState({ validatesRoomTypeName: false });
        this.setState({ validatesRoomTypeNameMsg: "" });
      }
      if (!this.state.data.id) {
        this.setState({ validateid: true });
        this.setState({ validateidMsg: "Mandatory field" });
      } else if (!this.state.data.id.match(/^[A-Za-z0-9\-\_\@]+$/)) {
        this.setState({ validateid: true });
        this.setState({
          validateidMsg:
            "Only alphabets, numbers & the following special characters allowed: @  -  _",
        });
      } else {
        this.setState({ validateid: false });
        this.setState({ validateidMsg: "" });
      }

      if (!this.state.data.maxRate) {
        this.setState({ validatemaxRate: true });
        this.setState({ validatemaxRateMsg: "Mandatory field" });
      }
      if (Number(this.state.data.maxRate) < 0) {
        this.setState({ validatemaxRate: true });
        this.setState({ validatemaxRateMsg: "Only positive numbers allowed" });
      }
      if (!this.state.data.minRate) {
        this.setState({ validateminRate: true });
        this.setState({ validateminRateMsg: "Mandatory field" });
      } else if (Number(this.state.data.minRate) < 0) {
        this.setState({ validateminRate: true });
        this.setState({ validateminRateMsg: "Only positive numbers allowed" });
      } else if (
        Number(this.state.data.minRate) >= Number(this.state.data.maxRate)
      ) {
        this.setState({ validateminRate: true });
        this.setState({
          validateminRateMsg:
            "Min rate can't be greater than or equal to the max rate",
        });
      }
      if (!this.state.data.occupancy) {
        this.setState({ validateoccupancy: true });
        this.setState({ validateoccupancyMsg: "Mandatory field" });
      } else if (!pattern.test(this.state.data.occupancy)) {
        this.setState({ validateoccupancy: true });
        this.setState({
          validateoccupancyMsg: "Only positive numbers allowed",
        });
      } else {
        this.setState({ validateoccupancy: false });
        this.setState({ validateoccupancyMsg: "" });
      }
      if (!this.state.data.childOccupancy) {
        this.setState({ validatechildOccupancy: true });
        this.setState({ validatechildOccupancyMsg: "Mandatory field" });
      } else if (!pattern.test(this.state.data.childOccupancy)) {
        this.setState({ validatechildOccupancy: true });
        this.setState({
          validatechildOccupancyMsg: "Only positive numbers allowed",
        });
      } else {
        this.setState({ validatechildOccupancy: false });
        this.setState({ validatechildOccupancyMsg: "" });
      }
      if (!this.state.data.inventory) {
        this.setState({ validateinventory: true });
        this.setState({ validateinventoryMsg: "Mandatory field" });
      } else if (!pattern.test(this.state.data.inventory)) {
        this.setState({ validateinventory: true });
        this.setState({
          validateinventoryMsg: "Only positive numbers allowed",
        });
      } else {
        this.setState({ validateinventory: false });
        this.setState({ validateinventoryMsg: "" });
      }
      if (!this.state.data.formula) {
        this.setState({ validateformula: true });
        this.setState({ validateformulaMsg: "Mandatory field" });
      } else {
        let firstTwoChars = this.state.data.formula.slice(0, 2);
        let FormulaPrefix = firstTwoChars.toUpperCase();
        let pattern = /^[a-zA-Z0-9()+\-*\/.]*$/;
        if (FormulaPrefix != "BR") {
          this.setState({ validateformula: true });
          this.setState({
            validateformulaMsg: "Room Type formula should start with BR.",
          });
        } else if (!pattern.test(this.state.data.formula)) {
          this.setState({ validateformula: true });
          this.setState({
            validateformulaMsg:
              "Only basic mathematical operation symbols, parentheses and decimal separator allowed.",
          });
        } else {
          this.setState({ validateformula: false });
          this.setState({ validateformulaMsg: "" });
        }
      }

      toast.error("Please check highlighted field(s)");
      return;
    } else {
      this.setState({ validateminRate: false });
      this.setState({ validateminRateMsg: "" });
      this.setState({ validatemaxRate: false });
      this.setState({ validatemaxRateMsg: "" });
      rt.id = rt.id || getNewrtId(this.props.hotel, rt);
      rt.hotelId = hotel.hotelId;
      this.setState({ disableButton: true });
      this.props.onclose();

      await toast.promise(YieldApi.writeRoomType(rt), {
        pending: "Creating Room Type '" + rt.shortDescription + "'",
        success: "Room Type created successfully",
        error: "Room Type creation failed",
      });
      this.setState({ disableButton: false });
      this.props.onUpdate(rt);
    }
  };

  updateRoomType = () => {
    let pattern = /^[0-9]*$/;
    let hotel = this.props.hotel;
    let room = this.state.data;
    let formulafirstTwoChars = this.state.data.formula.slice(0, 2);
    let FormulaCharPrefix = formulafirstTwoChars.toUpperCase();

    if (this.state.data.childOccupancy == null) {
      this.setState({ data: { childOccupancy: 0 } });
    }
    this.setState({ data: this.state.data });
    if (
      !this.state.data.shortDescription ||
      !this.state.data.id ||
      !this.state.data.minRate ||
      !this.state.data.maxRate ||
      Number(this.state.data.minRate) > Number(this.state.data.maxRate) ||
      Number(this.state.data.minRate) < 0 ||
      !this.state.data.occupancy ||
      !this.state.data.inventory ||
      !this.state.data.formula ||
      !this.state.data.shortDescription.match(/^[a-zA-Z0-9\s\+]+$/) ||
      !this.state.data.id.match(/^[A-Za-z0-9\-\_\@]+$/) ||
      !pattern.test(this.state.data.minRate) ||
      !pattern.test(this.state.data.maxRate) ||
      !pattern.test(this.state.data.occupancy) ||
      !pattern.test(this.state.data.childOccupancy) ||
      !pattern.test(this.state.data.inventory) ||
      !this.state.data.formula.match(/^[a-zA-Z0-9()+\-*\/.]*$/) ||
      !(FormulaCharPrefix == "BR") ||
      Number(this.state.data.minRate) < 0
    ) {
      if (!this.state.data.shortDescription) {
        this.setState({ validatesRoomTypeName: true });
        this.setState({
          validatesRoomTypeNameMsg: "Mandatory field",
        });
      } else if (
        !this.state.data.shortDescription.match(/^[a-zA-Z0-9\s\+]+$/)
      ) {
        this.setState({ validatesRoomTypeName: true });
        this.setState({
          validatesRoomTypeNameMsg:
            "Only Alphabets, numbers, + and spaces allowed.",
        });
      } else {
        this.setState({ validatesRoomTypeName: false });
        this.setState({ validatesRoomTypeNameMsg: "" });
      }
      if (!this.state.data.id) {
        this.setState({ validateid: true });
        this.setState({ validateidMsg: "Mandatory field" });
      } else if (!this.state.data.id.match(/^[A-Za-z0-9\-\_\@]+$/)) {
        this.setState({ validateid: true });
        this.setState({
          validateidMsg:
            "Only alphabets, numbers & the following special characters allowed: @  -  _",
        });
      } else {
        this.setState({ validateid: false });
        this.setState({ validateidMsg: "" });
      }

      if (!this.state.data.maxRate) {
        this.setState({ validatemaxRate: true });
        this.setState({ validatemaxRateMsg: "Mandatory field" });
      }
      if (Number(this.state.data.maxRate) < 0) {
        this.setState({ validatemaxRate: true });
        this.setState({ validatemaxRateMsg: "Only positive numbers allowed" });
      }
      if (!this.state.data.minRate) {
        this.setState({ validateminRate: true });
        this.setState({ validateminRateMsg: "Mandatory field" });
      } else if (Number(this.state.data.minRate) < 0) {
        this.setState({ validateminRate: true });
        this.setState({ validateminRateMsg: "Only positive numbers allowed" });
      } else if (
        Number(this.state.data.minRate) >= Number(this.state.data.maxRate)
      ) {
        this.setState({ validateminRate: true });
        this.setState({
          validateminRateMsg:
            "Min rate can't be greater than or equal to the max rate",
        });
      }
      if (!this.state.data.occupancy) {
        this.setState({ validateoccupancy: true });
        this.setState({ validateoccupancyMsg: "Mandatory field" });
      } else if (!pattern.test(this.state.data.occupancy)) {
        this.setState({ validateoccupancy: true });
        this.setState({
          validateoccupancyMsg: "Only positive numbers allowed",
        });
      } else {
        this.setState({ validateoccupancy: false });
        this.setState({ validateoccupancyMsg: "" });
      }
      if (!pattern.test(this.state.data.childOccupancy)) {
        this.setState({ validatechildOccupancy: true });
        this.setState({
          validatechildOccupancyMsg: "Only positive numbers allowed",
        });
      } else {
        this.setState({ validatechildOccupancy: false });
        this.setState({ validatechildOccupancyMsg: "" });
      }
      if (!this.state.data.inventory) {
        this.setState({ validateinventory: true });
        this.setState({ validateinventoryMsg: "Mandatory field" });
      } else if (!pattern.test(this.state.data.inventory)) {
        this.setState({ validateinventory: true });
        this.setState({
          validateinventoryMsg: "Only positive numbers allowed",
        });
      } else {
        this.setState({ validateinventory: false });
        this.setState({ validateinventoryMsg: "" });
      }
      if (!this.state.data.formula) {
        this.setState({ validateformula: true });
        this.setState({ validateformulaMsg: "Mandatory field" });
      } else {
        let firstTwoChars = this.state.data.formula.slice(0, 2);
        let FormulaPrefix = firstTwoChars.toUpperCase();
        let pattern = /^[a-zA-Z0-9()+\-*\/.]*$/;
        if (FormulaPrefix != "BR") {
          this.setState({ validateformula: true });
          this.setState({
            validateformulaMsg: "Room Type formula should start with BR.",
          });
        } else if (!pattern.test(this.state.data.formula)) {
          this.setState({ validateformula: true });
          this.setState({
            validateformulaMsg:
              "Only basic mathematical operation symbols, parentheses and decimal separator allowed.",
          });
        } else {
          this.setState({ validateformula: false });
          this.setState({ validateformulaMsg: "" });
        }
      }

      toast.error("Please check highlighted field(s)");
      return;
    } else {
      this.setState({ validateminRate: false });
      this.setState({ validateminRateMsg: "" });
      this.setState({ validatemaxRate: false });
      this.setState({ validatemaxRateMsg: "" });
      let difs = diff(
        room,
        JSON.parse(JSON.stringify(hotel.roomTypes[room.id])),
      );
      if (Object.keys(difs).length === 0) {
        toast.warn("No change detected");
        this.props.onclose();
        return;
      }
      room.hotelId = hotel.hotelId;
      this.state.disableButton = true;
      this.props.onclose();
      toast.promise(YieldApi.writeRoomType(room), {
        pending:
          "Room Type - '" +
          this.state.data.shortDescription +
          "' update in progress...",
        error:
          "Room Type - '" +
          this.state.data.shortDescription +
          "' modification failed!",
        success:
          "Room Type - '" +
          this.state.data.shortDescription +
          "' updated successfully.",
      });
    }
  };
  onBlurOfInputFields = (event: any) => {
    let pattern = /^[0-9]*$/;
    let fieldName = event.target.name;
    let value = event.target.value;
    switch (fieldName) {
      case "shortDescription":
        if (!value) {
          this.setState({ validatesRoomTypeName: true });
          this.setState({
            validatesRoomTypeNameMsg: "Mandatory field",
          });
        } else if (!value.match(/^[a-zA-Z0-9\s\+]+$/)) {
          this.setState({ validatesRoomTypeName: true });
          this.setState({
            validatesRoomTypeNameMsg:
              "Only Alphabets, numbers, + and spaces allowed.",
          });
        } else {
          this.setState({ validatesRoomTypeName: false });
          this.setState({ validatesRoomTypeNameMsg: "" });
        }
        break;
      case "occupancy":
        if (!value) {
          this.setState({ validateoccupancy: true });
          this.setState({ validateoccupancyMsg: "Mandatory field" });
        } else if (!pattern.test(value)) {
          this.setState({ validateoccupancy: true });
          this.setState({
            validateoccupancyMsg: "Only positive numbers allowed",
          });
        } else {
          this.setState({ validateoccupancy: false });
          this.setState({ validateoccupancyMsg: "" });
        }
        break;
      case "childOccupancy":
        if (this.props.isNew && !value) {
          this.setState({ validatechildOccupancy: true });
          this.setState({
            validatechildOccupancyMsg: "Mandatory field",
          });
        } else if (Number(value) < 0) {
          this.setState({ validatechildOccupancy: true });
          this.setState({
            validatechildOccupancyMsg: "Only positive numbers allowed",
          });
        } else {
          this.setState({ validatechildOccupancy: false });
          this.setState({ validatechildOccupancyMsg: "" });
        }
        break;
      case "id":
        if (!value) {
          this.setState({ validateid: true });
          this.setState({ validateidMsg: "Mandatory field" });
        } else if (!value.match(/^[A-Za-z0-9\-\_\@]+$/)) {
          this.setState({ validateid: true });
          this.setState({
            validateidMsg:
              "Only alphabets, numbers & the following special characters allowed: @  -  _",
          });
        } else {
          this.setState({ validateid: false });
          this.setState({ validateidMsg: "" });
        }
        break;
      case "minRate":
        console.log("minRate=>", value);
        this.setState({ validateminRate: false });
        this.setState({ validateminRateMsg: "" });
        if (!value) {
          this.setState({ validateminRate: true });
          this.setState({ validateminRateMsg: "Mandatory field" });
        } else if (Number(value) < 0) {
          this.setState({ validateminRate: true });
          this.setState({
            validateminRateMsg: "Only positive numbers allowed",
          });
        } else if (Number(value) >= Number(this.state.data.maxRate)) {
          this.setState({ validateminRate: true });
          this.setState({
            validateminRateMsg:
              "Min rate can't be greater than or equal to the max rate",
          });
        }
        break;
      case "inventory":
        if (!value) {
          this.setState({ validateinventory: true });
          this.setState({ validateinventoryMsg: "Mandatory field" });
        } else if (!pattern.test(value)) {
          this.setState({ validateinventory: true });
          this.setState({
            validateinventoryMsg: "Only positive numbers allowed",
          });
        } else {
          this.setState({ validateinventory: false });
          this.setState({ validateinventoryMsg: "" });
        }
        break;
      case "formula":
        if (!value) {
          this.setState({ validateformula: true });
          this.setState({ validateformulaMsg: "Mandatory field" });
        } else {
          let firstTwoChars = this.state.data.formula.slice(0, 2);
          let FormulaPrefix = firstTwoChars.toUpperCase();
          let pattern = /^[a-zA-Z0-9()+\-*\/.]*$/;

          if (FormulaPrefix != "BR") {
            this.setState({ validateformula: true });
            this.setState({
              validateformulaMsg: "Room Type formula should start with BR.",
            });
          } else if (!pattern.test(this.state.data.formula)) {
            this.setState({ validateformula: true });
            this.setState({
              validateformulaMsg:
                "Only basic mathematical operation symbols, parentheses and decimal separator allowed.",
            });
          } else {
            this.setState({ validateformula: false });
            this.setState({ validateformulaMsg: "" });
          }
        }
        break;
      case "maxRate":
        this.setState({ validatemaxRate: false });
        this.setState({ validatemaxRateMsg: "" });
        if (!value) {
          this.setState({ validatemaxRate: true });
          this.setState({ validatemaxRateMsg: "Mandatory field" });
        } else if (Number(value) < 0) {
          console.log("maxRate 2=>", value, Number(value));
          this.setState({ validatemaxRate: true });
          this.setState({
            validatemaxRateMsg: "Only positive numbers allowed",
          });
        } else if (Number(value) <= Number(this.state.data.minRate)) {
          this.setState({ validateminRate: true });
          this.setState({
            validateminRateMsg:
              "Min rate can't be greater than or equal to the max rate",
          });
        } else {
          this.setState({ validateminRate: false });
          this.setState({ validateminRateMsg: "" });
        }
        break;
      default:
        this.setState({ validatesRoomTypeName: false });
        this.setState({ validatesRoomTypeNameMsg: "" });
    }
  };

  handleOnClose(rtData: any) {
    this.props.onclose();
  }

  deepEqual(obj1: any, obj2: any) {
    delete obj2.validate;

    if (typeof obj1 !== typeof obj2) {
      return false;
    }

    if (typeof obj1 !== "object" || obj1 === null) {
      return obj1 === obj2;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  render() {
    const rtData = this.state.data;
    let isAdminUser =
      appStore.meta._user.permissions.admin[0] === "*" ? true : false;
    return (
      <MuiThemeProvider theme={theme}>
        <FormControl style={{ display: "inline-block", width: "100%" }}>
          {this.props.isNew && (
            <div style={{ marginLeft: 25, fontSize: 20 }}>
              <b>New Room Type</b>
            </div>
          )}
          {!this.props.isNew && (
            <div style={{ marginLeft: 25, fontSize: 20 }}>
              <b>Edit Room Plan:</b>{" "}
              <span style={{ color: "#d93025" }}>
                {rtData.shortDescription}
              </span>{" "}
              ({rtData.id})
            </div>
          )}
          <br />
          <TextField
            style={{ marginTop: 20 }}
            id="outlined-basic"
            label="Room Type Name"
            variant="outlined"
            name="shortDescription"
            onChange={this.handleChange}
            disabled={!isAdminUser}
            defaultValue={rtData.shortDescription}
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validatesRoomTypeName}
            helperText={this.state.validatesRoomTypeNameMsg}
            inputProps={{ maxLength: 20 }}
            required
          />
          <TextField
            style={{
              marginTop: 20,
            }}
            id="outlined-basic"
            label="ID"
            variant="outlined"
            name="id"
            onChange={this.handleChange}
            disabled={!isAdminUser || this.props.unEditable}
            defaultValue={rtData.id}
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validateid}
            helperText={this.state.validateidMsg}
            inputProps={{
              maxLength: 20,
            }}
            required
          />
          <TextField
            style={{
              marginTop: 20,
            }}
            name="occupancy"
            defaultValue={rtData.occupancy}
            onChange={this.handleChange}
            label="Occupancy"
            variant="outlined"
            disabled={!isAdminUser || this.props.unEditable}
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validateoccupancy}
            helperText={this.state.validateoccupancyMsg}
            required
          />
          <TextField
            style={{ marginTop: 20 }}
            type="Number"
            name="maxRate"
            defaultValue={rtData.maxRate}
            onChange={this.handleChange}
            disabled={!isAdminUser}
            label="Maximum Rate"
            variant="outlined"
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validatemaxRate}
            helperText={this.state.validatemaxRateMsg}
            inputProps={{ maxLength: 20 }}
            required
          />
          <br />
          <TextField
            style={{ marginTop: 20 }}
            name="formula"
            defaultValue={rtData.formula}
            onChange={this.handleChange}
            disabled={!isAdminUser}
            label="Formula"
            variant="outlined"
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validateformula}
            helperText={this.state.validateformulaMsg}
            inputProps={{ maxLength: 40 }}
            required
          />
          <TextField
            style={{ marginTop: 20 }}
            name="inventory"
            defaultValue={rtData.inventory}
            onChange={this.handleChange}
            disabled={!isAdminUser}
            label="Number of Rooms"
            variant="outlined"
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validateinventory}
            helperText={this.state.validateinventoryMsg}
            inputProps={{ maxLength: 20 }}
            required
          />
          <TextField
            style={{
              marginTop: 20,
            }}
            name="childOccupancy"
            defaultValue={
              !this.props.isNew && !rtData.childOccupancy
                ? 0
                : rtData.childOccupancy
            }
            onChange={this.handleChange}
            label="Child Occupancy"
            variant="outlined"
            disabled={!isAdminUser || this.props.unEditable}
            inputProps={{
              maxLength: 20,
            }}
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validatechildOccupancy}
            helperText={this.state.validatechildOccupancyMsg}
            required
          />
          <TextField
            style={{ marginTop: 20 }}
            type="Number"
            name="minRate"
            defaultValue={rtData.minRate}
            onChange={this.handleChange}
            disabled={!isAdminUser}
            label="Minimum Rate"
            variant="outlined"
            onBlur={this.onBlurOfInputFields.bind(this)}
            error={this.state.validateminRate}
            helperText={this.state.validateminRateMsg}
            inputProps={{ maxLength: 20 }}
            required
          />
          <br />
          <br />
          <div style={{ float: "right" }}>
            <Button
              disabled={this.state.disableButton}
              id="room-type-cross"
              aria-label="cancel"
              onClick={(rtData: any) => this.handleOnClose(rtData)}
              variant="contained"
              style={{
                backgroundColor: "#808080",
                color: "#ffffff",
                marginBottom: "10px",
                marginRight: "10px",
                cursor: this.state.disableButton ? "default" : "pointer",
              }}
            >
              {isAdminUser ? "Cancel" : "Close"}
            </Button>
            {isAdminUser && (
              <Button
                disabled={this.state.disableButton}
                aria-label="create"
                id="room-type-tick"
                onClick={() =>
                  this.props.isNew
                    ? this.createRoomType()
                    : this.updateRoomType()
                }
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#ce0e29",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginBottom: "10px",
                  cursor: this.state.disableButton ? "default" : "pointer",
                }}
              >
                Save
              </Button>
            )}
          </div>
        </FormControl>
      </MuiThemeProvider>
    );
  }
}
export default EditRoomPanel;

const getNewrtId = (hotel: any, rtData: any): string => {
  let id = "";
  id += rtData.shortDescription
    .trim()
    .replace(/[\u{0080}-\u{FFFF}]/gu, "")
    .replace(/\s/g, "")
    .toUpperCase();
  let suffixIdx = 1;
  while (Object.keys(hotel.rommTypes).indexOf(id) != -1) {
    id += "" + suffixIdx;
    suffixIdx++;
  }
  return id;
};
