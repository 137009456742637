import { BackendEvent } from "./../models/yield.models";
import {
  IExecSummaryPutrequest,
  IDeleteFileRequest,
  ILeftToSellUpdateList,
  IEditSubscriptionlist,
  IFetchCalendarEventsRequest,
  IBulkManualRateUpdate,
  IHotelConf,
  IOWSOverrideUpdate,
  BulkOwsOverridePayload,
} from "./../models/api.models";
import { API } from "aws-amplify";
import {
  IAvailabilityUpdate,
  IDayUpdate,
  IRateUpdate,
  ILeftToSellUpdate,
  ISuggestedBaseRateUpdate,
  IRestrictionsUpdate,
  IManualRateUpdate,
  INotify,
  IRewardsUpdate,
  IRatePlanConf,
  IRoomType,
  IDeleteRateplan,
  IHistoryRequest,
  IDeleteRoomType,
  ISuggestionParams,
  IFileUploadLinkRequest,
  IFileUpload,
  IFileRequest,
} from "../models/api.models";
import axios from "axios";

export class YieldApi {
  static async notify(body: INotify) {
    return API.post("yield", `/notify`, { body });
  }

  static async list(
    hotelId: number,
    startDate: string,
    endDate: string,
  ): Promise<any> {
    return API.get("backend", `/days/${hotelId}/${startDate}/${endDate}`, null);
  }

  static async publishAvailabilitiesInView(body: IDayUpdate) {
    return API.post("yield", `/publishAvailabilities`, { body });
  }

  static async publishRatesInView(body: IDayUpdate) {
    return API.post("yield", `/publishrates`, { body });
  }

  static async publishRestrictions(body: IDayUpdate) {
    return await API.post("yield", `/publishrestrictions`, { body });
  }

  static async getPickupData(hotelId: number, updatedDate: number) {
    return API.get("yield", `/pickup/${hotelId}/${updatedDate}`, {});
  }

  static async updateAvailability(availabilities: IAvailabilityUpdate[]) {
    const request = (body: IAvailabilityUpdate) =>
      API.put("yield", "/availability", { body });
    return Promise.all(availabilities.map(request));
  }

  static async fetchCalendarEvents(request: IFetchCalendarEventsRequest) {
    return API.get(
      "yield",
      `/event/${request.hotelId}/${request.start}/${request.end}`,
      {},
    );
  }

  static async updateCalendarEvents(body: BackendEvent) {
    return API.put("yield", `/event/${body.hotelId}`, { body });
  }

  static async updateLeftToSell(updates: ILeftToSellUpdate[]) {
    const request = (body: ILeftToSellUpdate) =>
      API.put("backend", `/left`, { body });
    return Promise.all(updates.map(request));
  }

  static async updateLeftToSellList(body: ILeftToSellUpdateList) {
    return API.put("backend", `/leftUpdates`, { body });
  }

  static async ingestOta(body: IDayUpdate) {
    return API.post("yield", `/ingestOta`, { body });
  }

  static async updateManualRates(rates: IManualRateUpdate[]) {
    const request = (body: IManualRateUpdate) =>
      API.post("yield", `/writemanualrate`, { body });
    return Promise.all(rates.map(request));
  }

  static async writeOWSOverrideSQS(body: IOWSOverrideUpdate) {
    return API.put("yield", `/owsoverride/sqs`, { body });
  }

  static async removeSuggestedDataAfterAcceptOrReject(
    body: ISuggestedBaseRateUpdate,
  ) {
    return API.put("yield", `/removeSuggestedRate`, { body });
  }

  static async updateSuggestedBaseRate(updates: ISuggestedBaseRateUpdate[]) {
    const request = (body: ISuggestedBaseRateUpdate) =>
      API.put("yield", `/suggestedrate`, { body });
    return Promise.all(updates.map(request));
  }

  static async writeManualRate(body: IManualRateUpdate) {
    return await API.post("yield", `/writemanualrate`, { body });
  }

  static async writeRestrictions(body: IRestrictionsUpdate) {
    return await API.post("yield", `/writerestrictions`, { body });
  }

  static async resyncDay(body: IDayUpdate) {
    return await API.post("backend", `/resyncDay`, { body });
  }

  static async bulkUpdateOwsOverride(body: BulkOwsOverridePayload) {
    return API.put("yield", `/owsoverride`, { body });
  }

  static async bulkUpdateRates(body: IRateUpdate) {
    return API.post("yield", `/bulkrates`, { body });
  }

  static async bulkUpdateManualRates(body: IBulkManualRateUpdate) {
    return API.post("yield", `/bulkmanualrates`, { body });
  }

  static async bulkUpdateRewards(body: IRewardsUpdate) {
    return await API.post("yield", `/bulkrewards`, { body });
  }

  static async bulkUpdateRestrictions(body: IRestrictionsUpdate) {
    return await API.post("yield", `/bulkrestrictions`, { body });
  }

  static async writeRateplan(body: IRatePlanConf) {
    return await API.put("backend", `/writerateplan`, { body });
  }

  static async writeRoomType(body: IRoomType) {
    return await API.put("backend", `/writeRoomType`, { body });
  }

  static async writeRtSortOrder(body: IRoomType) {
    return await API.put("yield", `/writeRtSortOrder`, { body });
  }

  static async writeRpSortOrder(body: IRoomType) {
    return await API.put("yield", `/writeRpSortOrder`, { body });
  }

  static async deleteRoomType(body: IDeleteRoomType) {
    return await API.del(
      "backend",
      `/deleteRoomType/${body.hotelId}/${body.rtId}`,
      { body },
    );
  }

  static async updateHotelConfig(body: IHotelConf) {
    return await API.put("yield", `/updateHotelConfig`, { body });
  }

  static async addNewHotel(body: IHotelConf) {
    return await API.post("backend", `/addNewHotel`, { body });
  }

  static async writeSuggestionParams(body: ISuggestionParams) {
    return await API.put("backend", `/writeSuggestionParams/${body.hotelId}`, {
      body,
    });
  }

  static async getHistory(body: IHistoryRequest) {
    return await API.get(
      "backend",
      `/history/${body.hotelId}/${body.dayDate}`,
      { body },
    );
  }

  static async deleteRateplan(body: IDeleteRateplan) {
    return await API.del(
      "backend",
      `/deleterateplan/${body.hotelId}/${body.rpId}`,
      { body },
    );
  }

  static async getUploadLink(body: IFileUploadLinkRequest) {
    return API.post("yield", `/uploadlink/${body.hotelId}`, { body });
  }

  static async getDownloadLink(body: IFileRequest) {
    return API.post("yield", `/downloadlink/${body.hotelId}`, { body });
  }

  static async deleteFile(body: IDeleteFileRequest) {
    return API.post("yield", `/deleteFile/${body.hotelId}`, { body });
  }

  static async uploadFile(body: IFileUpload) {
    let opt = {
      url: body.url || "toto",
      method: "put",
      data: body.data,
      headers: {
        "Content-Type": body.contentType,
        "content-disposition": "attachment",
      },
    };
    return axios.request(opt);
  }

  static async getExecSummaryItems(hotelId: number) {
    return await API.get("backend", `/execSummaryItems/${hotelId}`, {});
  }

  static async putExecSummaryItem(body: IExecSummaryPutrequest) {
    return await API.put("backend", `/execSummaryItem/${body.hotelId}`, {
      body,
    });
  }

  static async editSubscriptionList(body: IEditSubscriptionlist) {
    return await API.post(
      "backend",
      `/users/${body.hotelId}/editSubscriptionList`,
      { body },
    );
  }
}
