import { State } from "../bulk-update/bulk-update";

// Function to show error status
function showError(state: State, fieldName: keyof State): boolean {
  return !!(state.errors[fieldName] && state.touched[fieldName]);
}

// Function to get field classes
export function getFieldClasses(
  state: State,
  fieldName: keyof State,
  size?: "sm" | "lg",
): string {
  const baseClass = "form-control";
  const sizeClass = size ? `form-control-${size}` : "";
  const validationClass = showError(state, fieldName) ? "is-invalid" : "";

  return `${baseClass} ${sizeClass} ${validationClass}`.trim();
}
