import moment from "moment";
import {} from "../../models/yield.models";
import { hotel as gnHotel, restrictions } from "gn-shared";
import { OtaApi } from "../../api/ota";

const losLowerBound = 1;
const losUpperBound = 999;

const rowType: any = {
  calendarEvents: "calendar",
  inventory: "inventory",
  groups: "groups",
  suggestedBaseRate: "suggestedBaseRate",
  autoSuggested: "auroSuggested",
  actualBaseRate: "actualBaseRate",
  actualOWSBaseRate: "actualOWSBaseRate",
  leftToSell: "leftToSell",
  leftToSellUpdate: "leftToSellUpdate",
  pickupFrom: "pickupFrom",
  occupancy: "occupancy",
  ota: "ota",
  derivedRateFiller: "derivedRateFiller",
  rate: "rate",
  header: "header",
  date: "date",
  ratePlan: "ratePlan",
  roomType: "roomType",
  pickup: "pickup",
  rewards: "rewards",
  pickupDate: "pickupDate",
  lastUpdateLeftTosell: "lastUpdateLeftTosell",
  marketDemand: "marketDemand",
  marketDemandPicker: "marketDemandPicker",
  lastUpdateOta: "lastUpdateOta",
  compSetDropDownChange: "compSetDropDownChange",
};

const getHeader = (hotel: any) => {
  let globalResSuffix;
  if (hotel.meta.globalRestriction) {
    switch (hotel.meta.globalRestriction.key) {
      case restrictions.TYPE.MAX_DAYS:
        globalResSuffix =
          "  -  MaxLos  : " + hotel.meta.globalRestriction.value;
        break;
      case restrictions.TYPE.MIN_DAYS:
        globalResSuffix = "  -  MinLos : " + hotel.meta.globalRestriction.value;
        break;
    }
  }
  var res: Map<number, any> = new Map<number, any>();
  globalResSuffix = "";
  //invariant one
  let idx = 0;
  res.set(idx++, {
    type: rowType.suggestedBaseRate,
    caption: "Suggested Base Rate",
    isAutomatic: hotel.suggestionParams.isEnabled === "true" ? true : false,
  });

  res.set(idx++, { type: rowType.actualBaseRate, caption: "BASE RATE" });
  if (hotel.cm.channelConfig && hotel.cm.channelConfig.ows) {
    res.set(idx++, {
      type: rowType.actualOWSBaseRate,
      caption: "OWS BASE RATE",
      isReadOnly: false,
    });
  }

  res.set(idx++, {
    type: rowType.leftToSell,
    caption: "Left to Sell",
    isReadOnly: true,
  });

  if (hotel.cm.id === "guestline" || hotel.pms.id === "hotsoft") {
    res.set(idx++, {
      type: rowType.pickupFrom,
      caption: "Pickup From",
      isReadOnly: true,
    });
  } else {
    res.set(idx++, {
      type: rowType.leftToSellUpdate,
      caption: "Left to Sell updates",
    });
  }

  res.set(idx++, {
    type: rowType.occupancy,
    caption: "Occupancy",
    isReadOnly: true,
  });
  res.set(idx++, {
    type: rowType.derivedRateFiller,
    caption: "Derived rates",
    isReadOnly: true,
    isCollapsible: true,
    foldedRT: true,
  });
  if (globalResSuffix) {
    res.get(idx).caption += globalResSuffix;
  }
  return res;
};

class Rowprop {
  private map: any;
  private eventRowsIndexes: number[] = [];
  private compsetRowsIndexes: number[] = [];
  publishingInventoryEnabled: boolean = false;
  owsFormulas: any[] = [];
  defaultCompsetType: string = "";
  globalParams: any;
  lastRowIndex: number = 0;
  lastRateIndex: number = 0;
  collapsibleCaptions: number[] = [];
  hiddenRowsDT: number[] = [];
  showRowsDT: number[] = [];
  hiddenRows: number[] = [];
  rtConf: any = {};
  pickupRange: any;
  pickupFrom: any;
  marketDemand: any;
  marketDemandPicker: any;
  compSetDropDownChange: any;
  constructor(map: Map<number, any>, rtConf: any, globalParams: any) {
    this.map = map;
    map.forEach((obj, index) => {
      if (
        obj.type === "roomType" ||
        obj.type === "ratePlan" ||
        obj.type === "rewards"
      ) {
        this.hiddenRowsDT.push(index);
      }
      if (obj.type !== "ratePlan") {
        this.showRowsDT.push(index);
      }
    });
    this.rtConf = rtConf;
    this.globalParams = globalParams || {};
    for (let k of map.keys()) {
      let rp = map.get(k);
      if (rp.isCollapsible) {
        this.collapsibleCaptions.push(k);
      }
      switch (rp.type) {
        case rowType.ratePlan:
          this.lastRateIndex = this.lastRateIndex < k ? k : this.lastRateIndex;
          break;
        case rowType.calendarEvents:
        case rowType.groups:
        case rowType.inventory:
          this.eventRowsIndexes.push(k);
          break;
        case rowType.ota:
          this.compsetRowsIndexes.push(k);
          break;
      }

      this.lastRowIndex = k > this.lastRowIndex ? k : this.lastRowIndex;
    }
    for (let caption of this.collapsibleCaptions) {
      this.collapseSection(caption);
    }
  }
  foldAllTD() {
    this.map.forEach((obj: { type: string }, index: number) => {
      this.map.get(index).folded = true;
    });
  }
  get(i: number): any {
    return this.map.get(i);
  }
  getPickupFrom(): any {
    return this.pickupFrom.valueOf();
  }
  getMarketDemandPickup(): any {
    return this.marketDemand.valueOf();
  }

  updateCompSetDropdownChange(data: any): any {
    this.compSetDropDownChange = data;
  }

  getCompSetDropdownChange(): any {
    return this.compSetDropDownChange;
  }

  updatePickupFrom(pickupFrom: any) {
    this.pickupFrom = moment(pickupFrom).utc().set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  updateMarketDemandPickup(marketDemand: any) {
    this.marketDemand = moment(marketDemand).utc().set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    console.log("market demand pickup", this.marketDemand);
  }
  caption(i: number): string {
    return this.map.get(i) ? this.map.get(i).caption : "";
  }
  isCollapsible(i: number): boolean {
    return this.map.get(i).isCollapsible ? true : false;
  }

  isCollapsed(i: number): boolean {
    return this.map.get(this.getStartOfCollapsibleSection(i)).folded;
  }
  getEndOfCollpasibleSection(i: number): number {
    let c = this.collapsibleCaptions.indexOf(i);
    return c + 1 >= this.collapsibleCaptions.length
      ? this.lastRateIndex + 1
      : this.collapsibleCaptions[c + 1];
  }
  getStartOfCollapsibleSection(i: number): number {
    //last clopasible that is <= current row
    let start = 0;
    for (let idx = 0; idx <= i; idx++) {
      if (this.map.get(idx).isCollapsible) {
        start = idx;
      }
    }
    return start;
  }
  collapseSection(start: number) {
    let end = this.getEndOfCollpasibleSection(start);
    for (let i = start + 1; i < end; i++) {
      this.hiddenRows.push(i);
    }
    this.get(start).folded = true;
  }
  unCollapseSection(start: number): number[] {
    let rowToShow: number[] = [];

    let end = this.getEndOfCollpasibleSection(start);

    for (let i = start + 1; i < end; i++) {
      rowToShow.push(i);
    }
    this.hiddenRows = this.hiddenRows.filter((v) => {
      return rowToShow.indexOf(v) === -1;
    });
    this.get(start).folded = false;
    return rowToShow;
  }
  getEventRowsIdx(): number[] {
    return this.eventRowsIndexes;
  }
  getCompsetRowsIdx(): number[] {
    return this.compsetRowsIndexes;
  }
  getType(i: number): string {
    return this.map.get(i) ? this.map.get(i).type : "";
  }
  isRoomTypeRow(i: number): boolean {
    return this.map.get(i).type === rowType.roomType;
  }
  isRoomRateplan(i: number): boolean {
    return this.map.get(i).type === rowType.ratePlan;
  }
  isReadOnly(i: number): boolean {
    return this.map.get(i).isReadOnly ? true : false;
  }
  isRestrictionRestricted(i: number, key: string): boolean {
    return (
      this.map.get(i).restrictionExclusions &&
      this.map.get(i).restrictionExclusions.indexOf(key) !== -1
    );
  }
  isPublishingInventory() {
    return this.publishingInventoryEnabled;
  }
}

const getRoomTypesConfig = function (hotelConf: HotelRates): any {
  let conf: Array<any> = [];
  try {
    let h = gnHotel(hotelConf);
    const roomTypes = h.getRoomTypes().sort((a: any, b: any) => {
      return a.sortOrder - b.sortOrder;
    });
    for (let rt of roomTypes) {
      if (!rt.deleted) {
        let rps = h.getRelatedRatePlans(rt).sort((a: any, b: any) => {
          return a.sortOrder - b.sortOrder;
        });
        var filteredRps: any[] = [];
        for (let rp of rps) {
          //let dateTo =
          //  DateTime.fromISO(appStore.yieldSheet.dateTo) ||
          //  DateTime.local().endOf("month");
          //console.log("dateTo", dateTo);
          //console.log("rp.deleted", rp.deleted);
          if (
            //(rp.deleted && dateTo > DateTime.fromISO(rp.deleted)) ||
            !rp.deleted
          ) {
            console.log("This happened");
            let prefix = rp.modeOfSale === "OWS" ? "[OWS] " : "";
            prefix = rp.modeOfSale === "OTA" ? "[OTA] " : prefix;
            prefix = rp.modeOfSale === "GDS" ? "[GDS] " : prefix;
            prefix = rp.modeOfSale === "Rewards" ? "[REWARDS] " : prefix;
            //compute if his rp/is subject to a sale

            filteredRps.push({
              id: rp.id,
              shortDescription:
                prefix + rp.shortDescription + (rp.deleted ? " (Deleted)" : ""),
              formula: rp.formula,
              defaultRate: rp.defaultRate,
              modeOfSale: rp.modeOfSale,
              restrictionExclusions: rp.restrictionExclusions,
              DOWCloseOut: rp.DOWCloseOut,
              isManual: rp.isManual,
              autoCloseOut: rp.autoCloseOut,
              deleted: rp.deleted ? rp.deleted : false,
            });
          }
        }
        conf.push({
          id: rt.id,
          shortDescription:
            rt.shortDescription + (rt.deleted ? " (Deleted)" : ""),
          ratePlans: filteredRps,
          restrictionExclusions: rt.restrictionExclusions,
        });
      }
    }
  } catch (err) {
    console.error(err);
  }
  return conf;
};

const computeRowProps = async function (hotel: any) {
  var res: Map<number, any> = getHeader(hotel);
  let h = gnHotel(hotel);
  if (h.hasManualRatePlan()) {
    let brRowProp = res.get(1);
    brRowProp.hasManualRP = true;
    res.set(1, brRowProp);
  }

  let idx = res.size;
  let rtConf = getRoomTypesConfig(hotel);

  let i = idx;
  for (let e in rtConf) {
    let v = {
      type: rowType.roomType,
      caption: rtConf[e].shortDescription,
      isReadOnly:
        hotel.cm.id === "guestline" ||
        hotel.meta.isPublishingInventory === "false"
          ? true
          : false,
      isCollapsible: true,
      folded: true,
      roomTypeId: rtConf[e].id,
      globalRes: h.meta.globalRestriction,
    };
    res.set(i, v);
    i++;
    for (let k in rtConf[e].ratePlans) {
      let rp = rtConf[e].ratePlans[k];
      let openPeriod = rp.autoCloseOut
        ? {
            start: moment(rp.autoCloseOut.openStart).valueOf(),
            end: moment(rp.autoCloseOut.openEnd).valueOf(),
          }
        : undefined;
      let v = {
        type: rowType.ratePlan,
        caption: rp.shortDescription,
        defaultRate: rp.defaultRate,
        isCollapsible: false,
        roomTypeId: rtConf[e].id,
        ratePlanId: rp.id,
        modeOfSale: rp.modeOfSale,
        restrictionExclusions: rp.restrictionExclusions,
        DOWCloseOut: rp.DOWCloseOut,
        isManual: rp.isManual,
        globalRes: h.meta.globalRestriction,
        autoCloseOut: openPeriod,
        deleted: rp.deleted ? rp.deleted : false,
      };
      res.set(i, v);
      i++;
    }
    idx = i;
  }
  if (h.hasManualRatePlan()) {
    let brRowProp = res.get(1);
    brRowProp.hasManualRP = true;
    res.set(1, brRowProp);
  }
  if (h.meta.hasRewards === true) {
    res.set(idx++, {
      type: rowType.rewards,
      caption: "Rewards",
      isReadOnly: true,
    });
  }
  if (h.cm.id !== "guestline" && hotel.pms.id !== "hotsoft") {
    res.set(idx++, {
      type: rowType.pickup,
      caption: "Pickup",
      isReadOnly: true,
    });
    res.set(idx++, {
      type: rowType.pickupDate,
      caption: "Pickup Date",
      isReadOnly: true,
    });

    res.set(idx++, {
      type: rowType.lastUpdateLeftTosell,
      caption: "Last update left To sell",
      isReadOnly: true,
    });
  }

  res.set(idx++, {
    type: rowType.marketDemand,
    caption: "Market Demand",
    isReadOnly: true,
  });

  // Add Compset row based on otaHotelId
  let compset =
    typeof hotel.ota.compSet == "undefined" ? [] : hotel.ota.compSet;
  if (compset.length > 0) {
    let competitors = await OtaApi.getOtaHotelCompList(hotel.ota.hotelId);
    for (let i = 0; i < compset.length; i++) {
      let competitor = competitors.find(
        (element: any) => element.id === compset[i],
      );
      if (typeof competitor !== "undefined") {
        res.set(idx++, {
          type: rowType.ota,
          caption: competitor.name,
          hotelName: competitor.name,
          otaHotelId: competitor.id,
          isReadOnly: true,
        });
      }
    }
  }

  res.set(idx++, {
    type: rowType.lastUpdateOta,
    caption: "Comp Set Update",
    isReadOnly: true,
  });

  let boundaries = restrictions.getLOSBoundaries(h.meta.globalRestriction);
  let rowProp = new Rowprop(res, rtConf, {
    LOSBoundaries: boundaries,
  });
  rowProp.defaultCompsetType = hotel.ota.defaultCompsetType;
  if ("isPublishingInventory" in hotel.meta) {
    if (
      hotel.meta.isPublishingInventory === true ||
      hotel.meta.isPublishingInventory === "true"
    ) {
      rowProp.publishingInventoryEnabled = true;
    }
  }
  if ("pickupRange" in hotel.meta) {
    rowProp.pickupRange = hotel.meta.pickupRange;
    let date = "2024-03-18";
    let onboardDate = hotel.hotelOnboardDate;
    let a = onboardDate ? onboardDate : date;
    let b = moment(moment().format("YYYY-MM-DD"));

    let diff = b.diff(a, "days");

    console.log(b.valueOf());

    if (hotel.meta.pickupRange > diff) {
      rowProp.pickupFrom = moment.utc(a + " 12:00").valueOf();
    } else {
      rowProp.pickupFrom = moment()
        .utc()
        .set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .subtract(hotel.meta.pickupRange, "days");
    }

    console.log(rowProp.pickupFrom, rowProp.pickupFrom.valueOf());
  } else {
    rowProp.pickupRange = 0;
    rowProp.pickupFrom = moment().utc().set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  if ("otaPickupRange" in hotel.meta) {
    rowProp.pickupRange = hotel.meta.otaPickupRange;

    let date = "2024-05-27";
    let onboardDate = hotel.hotelOnboardDate;
    let a = onboardDate ? onboardDate : date;
    let b = moment(moment().format("YYYY-MM-DD"));

    let diff = b.diff(a, "days");

    if (hotel.meta.otaPickupRange > diff) {
      rowProp.marketDemand = moment.utc(a + " 12:00").valueOf();
    } else {
      rowProp.marketDemand = moment()
        .utc()
        .set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .subtract(hotel.meta.otaPickupRange, "days");
    }
  } else {
    rowProp.marketDemand = 0;
    rowProp.marketDemand = moment().utc().set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }
  if (
    hotel.cm.channelConfig.ows &&
    hotel.cm.channelConfig.ows.SeasonnalConfig &&
    hotel.cm.channelConfig.ows.seasonnalFormula &&
    hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.length > 0
  ) {
    rowProp.owsFormulas = hotel.cm.channelConfig.ows.SeasonnalConfig.formulas;
  }
  return rowProp;
};

export { Rowprop, losLowerBound, losUpperBound, rowType, computeRowProps };
