import React, { Component, RefObject } from "react";
import List from "@material-ui/core/List";

import { YieldSheetService } from "../../services";
import ExecSummaryItem from "./execSummaryItem";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";

import SendIcon from "@material-ui/icons/Send";
import { Container, TextField, Button } from "@material-ui/core";
import commonfileUtils from "../common";
import { toast } from "react-toastify";
import { appStore } from "../../store";
import { hotel as gnHotel } from "gn-shared";
import withRouter from "../../withRouter";

const avatarsColors = [
  "crimson",
  "blueviolet",
  "brown",
  "darkblue",
  "darkcyan",
];
interface State {
  items: any[];
  editedText: string;
  editedAttachments: any[];
  isEdited: boolean;
  captureTextBox: string;
}
export interface IExecSummaryProps {
  activeHotelId: any;
  router: any;
}

class ExecSummary extends Component<IExecSummaryProps> {
  readonly state: State = {
    items: [],
    editedText: "",
    editedAttachments: [],
    isEdited: false,
    captureTextBox: "",
  };

  currentUser: string;
  hotelDisplayName: string;
  hotel: any;
  hotelId: number;
  editTextBoxref: RefObject<any>;
  newEntry: any;
  avatarColor: any;

  constructor(props: any) {
    super(props);
    this.hotel = gnHotel(appStore.meta.config.hotels[this.props.activeHotelId]);
    this.currentUser = appStore.meta._user.username;
    this.hotelId = this.hotel.hotelId;
    this.hotelDisplayName = this.hotel.info.name;
    this.editTextBoxref = React.createRef();
    this.fetchItems();
  }

  handleLeavePage = (event: any) => {
    event.returnValue = "Are you sure you want to leave?";
  };

  fetchItems = async () => {
    try {
      const items = await YieldSheetService.revanista.hotel.getExecSummaryItems(
        this.hotelId,
      );
      let userList = [];
      for (let i of items) {
        if (userList.indexOf(i.origin) == -1) {
          userList.push(i.origin);
        }
      }
      let avatarColor: any = {};
      let i = 0;
      for (let u of userList) {
        avatarColor[u] =
          avatarsColors[
            i < avatarsColors.length
              ? i
              : i - ((avatarsColors.length * i) % avatarsColors.length)
          ];
        i++;
      }
      this.avatarColor = avatarColor;
      this.setState({ items, editedAttachments: [] });
    } catch (err) {
      console.log(err);
    }
  };

  appendFile = (e: any) => {
    let a = this.state.editedAttachments;

    let p: Promise<any>[] = [];
    for (let file of e.target.files) {
      let tokens = file.name.split(".");
      p.push(
        YieldSheetService.revanista.files
          .getUploadLink(
            this.hotelId,
            file.type,
            tokens[tokens.length - 1],
            file.name,
          )
          .then((res: any) => {
            let url = res.presignedUrl;
            let filename = res.objectKey;
            let reader = new FileReader();
            reader.onload = function (e: any) {
              let blob = new Blob([new Uint8Array(e.target.result)], {
                type: file.type,
              });
              YieldSheetService.revanista.files
                .upload(url, file.type, blob)
                .catch((err: any) => {
                  toast.error("Error uploading " + file.name);
                  console.log(err);
                });
            };
            reader.readAsArrayBuffer(file);
            return { name: file.name.replace(/^.*[\\\/]/, ""), id: filename };
          }),
      );
    }
    Promise.all(p).then((v: any) => {
      if (v != undefined) {
        a.push(...v);
      }

      if (a.length > 0) {
        this.setState({ isEdited: true });
      }

      this.setState({ editedAttachments: a });
    });
  };

  deletefile = async (id: string, name: string) => {
    try {
      await commonfileUtils.deleteFile(this.hotelId, id);
      let a = this.state.editedAttachments;
      let filtered = a.filter((e) => e.name !== name);
      this.setState({ editedAttachments: filtered });
    } catch (err) {
      console.log(err);
      toast.error("Error deleting file");
    }
  };

  AddItem = (e: any) => {
    let text = this.editTextBoxref.current.value;
    this.editTextBoxref.current.value = "";
    let i = this.state.items;
    this.setState({ isEdited: false });
    console.log(text);

    let item = {
      //origin: this.currentUser,
      hotelId: this.hotelId,
      timestamp: moment().valueOf(),
      description: text,
      attachments: this.state.editedAttachments || [],
    };
    YieldSheetService.revanista.hotel
      .putExecSummaryItem(item)
      .then((result: any) => {
        this.fetchItems();
      });
  };

  handleOnChange = (event: any) => {
    console.log(event.target.value);
    this.setState({ captureTextBox: event.target.value });
  };

  render() {
    return (
      <div
        className="content"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Container
          style={{
            flex: 1,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <List>
            {this.state.items.map((item: any, index: number) => {
              return (
                <ExecSummaryItem
                  hotelId={this.hotelId}
                  item={item}
                  avatarColors={this.avatarColor}
                />
              );
            })}
          </List>
        </Container>
        <br />
        <Container style={{ marginBottom: "5vh" }}>
          {
            <Container style={{ paddingRight: 0 }}>
              <Container
                style={{
                  height: 100,
                  display: "inline-table",
                  paddingRight: 0,
                  paddingLeft: 35,
                }}
              >
                <TextField
                  id="outlined-textarea"
                  label="New entry"
                  style={{
                    width: "calc(100% - 130px)",
                    height: "100%",
                    display: "inline-grid",
                  }}
                  placeholder=""
                  inputRef={this.editTextBoxref}
                  onChange={this.handleOnChange}
                  multiline
                  variant="outlined"
                  name="execInput"
                />
                <label
                  htmlFor="contained-button-file"
                  style={{ width: "50px", display: "contents" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ backgroundColor: "darkgrey", height: "100%" }}
                    id="execAttachmentIcon"
                  >
                    <AttachFileIcon />
                  </Button>
                </label>

                <Button
                  aria-label="split"
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={this.AddItem}
                  style={{ width: "50px", height: "100%" }}
                  id="execSendIcon"
                >
                  <SendIcon />
                </Button>
                <input
                  data-fileindex={this.state.editedAttachments.length}
                  accept="*/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={this.appendFile}
                />
              </Container>
              <div style={{ paddingTop: "10px" }}>
                {this.state.editedAttachments.map(
                  (item: any, index: number) => {
                    return (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "20px", margin: "10px" }}
                        endIcon={
                          <DeleteIcon
                            style={{ color: "red" }}
                            onClick={() => {
                              this.deletefile(item.id, item.name);
                            }}
                          />
                        }
                      >
                        {item.name}
                      </Button>
                    );
                  },
                )}
              </div>
            </Container>
          }
        </Container>
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.newEntry = el;
          }}
        ></div>
      </div>
    );
  }
}

export default withRouter(ExecSummary);
