import { Component } from "react";
import withRouter from "../withRouter";
import { appStore } from "../store";
import { Outlet } from "react-router-dom";
import { AdminApi } from "../../api/admin";
import { computeRowProps } from "../yield-sheet/rowProp";
import "./homePage.css";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

let logo: string;
import(`assets/images/${process.env.REACT_APP_STAGE}/revanista-logo.png`).then(
  (module) => {
    logo = module.default;
  },
);

interface Props {
  router: any;
}

interface State {
  counter: number;
  searchInput: string;
}

class HomePage extends Component<Props, State> {
  readonly state: State = {
    counter: 0,
    searchInput: "",
  };
  hotel: any[];

  constructor(props: any) {
    super(props);

    this.hotel = appStore.meta.user.hotels;
  }

  componentDidMount(): void {
    this.setState({ counter: this.state.counter + 1 });
  }

  handleOnClick = async (data: any) => {
    appStore.requests.setAdmin("getHotelConfig", "fetching");
    AdminApi.getUsersHotelsMeta().then((response) => {
      let hotelTofetch: number[] = [];
      response.forEach((hotel: any) => {
        hotelTofetch.push(hotel.hotelId);
      });

      AdminApi.getHotelConfig(parseInt(data.hotelId))
        .then(async (h: any) => {
          let hotels = new Map<number, any>();
          hotels.set(h.hotelId, h);
          appStore.meta.setConfig("hotels", hotels);
          let rowProps = new Map<number, any>();
          for (let id of hotels.keys()) {
            let h = hotels.get(id);
            console.log("h is ", h);
            var rowProp = await computeRowProps(h);
            rowProps.set(id, rowProp);
          }
          appStore.meta.setConfig("rowProps", rowProps);
          appStore.requests.setAdmin("getHotelConfig", "success");
          return response;
        })
        .catch((err: any) => {
          appStore.meta.setConfig("hotels", {});
          appStore.requests.setAdmin("getHotelConfig", "error");
          throw err;
        });
    });
    appStore.changeActiveHotel(data);
    console.log(data);

    this.props.router.navigate(`${data.hotelId}`);
  };

  handleChange = (e: any) => {
    const value = e.target.value;
    this.setState({ searchInput: value });
  };

  render() {
    if (this.hotel.length === 1) {
      const singleUserHotelId = this.hotel[0].hotelId;
      let isSignedIn = localStorage.getItem("amplify-authenticator-authState");
      isSignedIn == "signedIn" &&
        this.props.router.navigate(`/${singleUserHotelId}`);
    }
    const { searchInput } = this.state;

    const searchFilter = this.hotel.filter((item: any) => {
      if (searchInput.length == 0) {
        return true;
      } else {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      }
    });

    const hotelName = appStore.meta.user.permissions.admin;

    console.log(hotelName);

    return (
      <div className="homeBody content">
        <img
          // onClick={this.handleOnHomePage}
          className="homePageLogo"
          src={logo}
          alt=""
        />
        <div className="hotelSearch">
          <TextField
            // style={{width: '50%'}}
            id="outlined-basic"
            label="Search"
            variant="outlined"
            name="searchHotel"
            inputProps={{ maxLength: 20 }}
            onChange={this.handleChange}
          />
        </div>
        <div className="hotelCards">
          {hotelName[0] === "*" && (
            <div
              className="cards"
              onClick={() => this.props.router.navigate(`/onboard-hotel`)}
            >
              <AddIcon className="apartmentIcon" />
              <h5 className="hotelFullName">Onboard Hotel</h5>
            </div>
          )}

          {searchFilter.map((data: any, key: any) => {
            return (
              <div className="cards" onClick={() => this.handleOnClick(data)}>
                <ApartmentIcon className="apartmentIcon" />
                <h5 className="hotelFullName">{data.name}</h5>
              </div>
            );
          })}
        </div>
        <Outlet />
      </div>
    );
  }
}

export default withRouter(HomePage);
