import { computeOtaValues } from "../yield-sheet.helpers";
import { GridSettings, renderers } from "handsontable";
import { Rowprop, rowType } from "../rowProp";
import DatePicker from "react-datepicker";
import React, { forwardRef } from "react";
import { createRoot } from "react-dom/client";
import moment from "moment";
let rowArray: number[] = [];

export function fillCaption(
  this: any,
  row: number,
  value: string,
  dayCount: number,
  prop: string | number,
  TD: HTMLElement,
  cellProperties: GridSettings,
  rowProps: Rowprop,
  yieldSheet: any,
  suggestionParams: string,
  hotel: any,
  instance: any,
  permissions: any,
  compset: any[],
  compareOtaRate: any,
  compareDemand: any,
  hotelOnboardDate: string,
  openSuggestionModalFunc: () => void,
  forceRender: () => void,
  computeLeftToSell: (e: any) => void,
) {
  let rowProp = rowProps.get(row);
  const plugin = instance.getPlugin("hiddenRows");

  if (rowProps.isCollapsible(row)) {
    const button = document.createElement("button");
    button.className = "icon-button";
    button.type = "button";

    let folded = rowProp.folded;
    let foldedRT = rowProp.foldedRT;

    if (rowProp.type == "derivedRateFiller") {
      button.innerHTML = `<ion-icon name='${
        foldedRT ? "ios-arrow-down" : "ios-arrow-forward"
      }'/>`;
      if (rowProp.foldedRT == false) {
        plugin.hideRows(rowProps.hiddenRowsDT);
      } else {
        plugin.showRows(rowProps.showRowsDT);
      }
    } else {
      button.innerHTML = `<ion-icon name='${
        folded ? "ios-arrow-forward" : "ios-arrow-down"
      }'/>`;
    }
    button.addEventListener("click", () => {
      if (rowProp.type == "derivedRateFiller") {
        if (rowProp.foldedRT == false) {
          plugin.hideRows(rowProps.hiddenRowsDT);
          rowProp.foldedRT = true;
          rowProps.foldAllTD();
        } else {
          plugin.showRows(rowProps.showRowsDT);
          rowProp.foldedRT = false;
        }
      } else {
        if (rowProp.folded) {
          let rowToshow = rowProps.unCollapseSection(row);
          plugin.showRows(rowToshow);
          rowProp.folded = false;
        } else {
          rowProps.collapseSection(row);
          plugin.hideRows(rowProps.hiddenRows);
        }
      }
      //instance.setDataAtCell(0, 0, '  ')
      instance.render();
    });
    const div = document.createElement("div");
    div.innerText = ` ${value}`;
    div.appendChild(button);
    TD.appendChild(div);
    TD.className = applyCellPropertiesClassName(cellProperties);
    if (rowProp.type == "roomType") {
      TD.className += " margin-left-10px";
    }
  } else {
    if (
      suggestionParams === "true" &&
      rowProp.type == rowType.suggestedBaseRate &&
      rowProp.isAutomatic
    ) {
      const div = document.createElement("div");
      div.innerText = ` ${value}   `;

      const button = document.createElement("button");
      button.className = "icon-button fas fa-cogs";
      button.addEventListener("click", (event) => {
        openSuggestionModalFunc();
        console.log(openSuggestionModalFunc());

        event.stopPropagation();
        event.preventDefault();
      });

      div.appendChild(button);

      TD.appendChild(div);
      TD.className = applyCellPropertiesClassName(cellProperties);
      return TD;
    }

    if (rowProp.type == rowType.leftToSellUpdate) {
      const div = document.createElement("div");

      div.innerText = ` ${value}  `;

      const saveButton = document.createElement("button");
      const cancelButton = document.createElement("button");
      saveButton.type = "button";
      cancelButton.type = "button";
      saveButton.innerHTML = " Apply ";
      cancelButton.innerHTML = " Clear ";
      div.appendChild(saveButton);
      div.appendChild(cancelButton);

      cancelButton.addEventListener("click", forceRender);
      saveButton.addEventListener("click", computeLeftToSell);
      saveButton.className = " btn-small";
      cancelButton.className = " btn-small";
      TD.appendChild(div);
      TD.className = applyCellPropertiesClassName(cellProperties);
      return TD;
    }

    if (rowProp.type == rowType.pickupFrom) {
      const div = document.createElement("div");

      div.innerText = ` ${value} `;

      // @ts-ignore
      const CustomInputDatePicker = forwardRef(({ value, onClick }, ref) =>
        React.createElement(
          "button",
          { className: "btn-small", onClick, ref },
          value,
        ),
      );

      if (
        typeof hotelOnboardDate == "undefined" ||
        moment(hotelOnboardDate).isBefore("2024-03-18") // date when back-end feature was released
      ) {
        hotelOnboardDate = "2024-03-18";
      }
      // @ts-ignore
      const datePicker = React.createElement(DatePicker, {
        selected: rowProps.getPickupFrom(),
        portalId: "root-portal",
        dateFormat: "d LLL yyyy",
        locale: "en-GB",
        selectsStart: true,
        minDate: new Date(hotelOnboardDate),
        maxDate: new Date(),
        customInput: React.createElement(CustomInputDatePicker),
        onChange: (date: Date) => {
          rowProps.updatePickupFrom(date);
          forceRender();
        },
      });

      // Create a DOM node to render the date picker
      const container = document.createElement("div");
      container.id = "date-picker-container";

      // Render the date picker to the DOM node
      const root = createRoot(container);
      root.render(datePicker);

      // Wrap the text and the date picker in a div
      const row = document.createElement("div");
      row.className = "pickup-from-row";
      row.appendChild(div);
      row.appendChild(container);

      TD.appendChild(row);
      TD.className = applyCellPropertiesClassName(cellProperties);
      return TD;
    }
    if (rowProp.type === rowType.marketDemand) {
      const div = document.createElement("div");

      div.innerText = ` ${value} `;

      // @ts-ignore
      const CustomInputDatePicker = forwardRef(({ value, onClick }, ref) =>
        React.createElement(
          "button",
          { className: "btn-small", onClick, ref },
          value,
        ),
      );

      if (
        typeof hotelOnboardDate == "undefined" ||
        moment(hotelOnboardDate).isBefore("2024-05-27") // date when back-end feature was released
      ) {
        console.log("worked");
        hotelOnboardDate = "2024-05-27";
      }
      // @ts-ignore
      const datePicker = React.createElement(DatePicker, {
        selected: rowProps.getMarketDemandPickup(),
        portalId: "root-portal",
        dateFormat: "d LLL yyyy",
        locale: "en-GB",
        selectsStart: true,
        minDate: new Date(hotelOnboardDate),
        maxDate: new Date(),
        customInput: React.createElement(CustomInputDatePicker),
        onChange: (date: Date) => {
          rowProps.updateMarketDemandPickup(date);
          forceRender();
        },
      });

      // Create a DOM node to render the date picker
      const container = document.createElement("div");
      container.id = "date-picker-container";

      // Render the date picker to the DOM node
      const root = createRoot(container);
      root.render(datePicker);

      // Wrap the text and the date picker in a div
      const row = document.createElement("div");
      row.className = "pickup-from-row";
      row.appendChild(div);
      row.appendChild(container);

      TD.appendChild(row);
      TD.className = applyCellPropertiesClassName(cellProperties);
      return TD;
    }
    if (rowProp.type == rowType.lastUpdateOta) {
      let changedValue = rowProps.getCompSetDropdownChange();
      console.log("changed value ======", changedValue);

      let defaultOTA;

      if (hotel.ota.defaultCompsetType == "oneGuestRO") {
        defaultOTA = "1 Guest RO";
      } else if (hotel.ota.defaultCompsetType == "oneGuestBB") {
        defaultOTA = "1 Guest BB";
      } else if (hotel.ota.defaultCompsetType == "BB") {
        defaultOTA = "2 Guest BB";
      } else {
        defaultOTA = "2 Guest RO";
      }

      const div = document.createElement("div");
      div.className = "dropdown-ota";

      console.log("butto ++++++++++++++++++++++++");

      const button = document.createElement("button");
      button.innerHTML = `${defaultOTA} &#9650;`;
      button.id = "dropdown-btn-ota";
      button.className = "dropbtn-ota";

      // Create div element for dropdown content
      const dropdownContent = document.createElement("div");
      // dropdownContent.className = "dropdown-content-ota";
      dropdownContent.id = "myDropdown";
      dropdownContent.style.display = "none";
      dropdownContent.style.position = "absolute";
      dropdownContent.style.bottom = "27px";
      dropdownContent.style.left = "0";
      dropdownContent.style.backgroundColor = "#f1f1f1";
      dropdownContent.style.width = "100px";
      dropdownContent.style.zIndex = "1";
      const listener = (event: any) => {
        //handled outside handsometable for perf
        let select = event == undefined ? changedValue : event.target.value;

        let selectValue = select;
        let selectText;
        rowProps.updateCompSetDropdownChange(selectValue);
        if (selectValue == "oneGuestRO") {
          selectText = "1 Guest RO";
        } else if (selectValue == "oneGuestBB") {
          selectText = "1 Guest BB";
        } else if (selectValue == "BB") {
          selectText = "2 Guest BB";
        } else {
          selectText = "2 Guest RO";
        }
        const m = document.createElement("span");
        m.innerHTML = `${selectText} &#9650;`;
        m.className = "overrideSpan";
        m.id = "overrideSpanId";
        console.log("m present ++++++++++");

        m.addEventListener("click", function () {
          if (dropdownContent.style.display === "none") {
            dropdownContent.style.display = "inline-block";
          } else {
            dropdownContent.style.display = "none";
          }
        });

        div.appendChild(m);
        for (let col = 1; col < dayCount + 1; col++) {
          for (let compsetRow of rowProps.getCompsetRowsIdx()) {
            let r = rowProps.get(compsetRow);
            let otaRoomName = compset[col][r.hotelName]
              ? compset[col][r.hotelName][selectValue + "RoomName"]
              : undefined;

            let compsetV = compset[col][r.hotelName]
              ? compset[col][r.hotelName][selectValue]
              : undefined;

            let compareOtaRates = compareOtaRate[col][r.hotelName]
              ? compareOtaRate[col][r.hotelName][selectValue]
              : undefined;

            let compareOtaRoomName = compareOtaRate[col][r.hotelName]
              ? compareOtaRate[col][r.hotelName][selectValue + "RoomName"]
              : undefined;

            let otaRoomNameAndRoomType = `${otaRoomName}`;

            let compareOtaRoomNameAndRoomType = `${compareOtaRoomName}`;

            let t = computeOtaValues(
              compsetRow, // just review this with row
              rowProps,
              selectValue,
              compset,
              col,
            );
            let s = document.getElementById(
              "otarate_" + compsetRow + "_" + col,
            );

            if (s) {
              s.innerText = t;

              let flag = document.createElement("div");

              let ans = compsetV - compareOtaRates;

              let tooltip = document.createElement("span");

              let hoverText = document.createElement("div");
              let compareRoomNameDiv = document.createElement("div");
              compareRoomNameDiv.className = "compareRoomNameDiv";
              let currentRoomNameElement = document.createElement("span");
              let prevRoomNameElement = document.createElement("span");
              let roomAndRateType = document.createElement("span");

              if (col == 1) {
                rowArray.push(compsetRow);
              }

              let lastCol = rowArray[hotel.ota.compSet.length - 1];
              let secondLast = rowArray[hotel.ota.compSet.length - 2];
              let thirdLast = rowArray[hotel.ota.compSet.length - 3];

              if (
                compsetRow == lastCol ||
                compsetRow == secondLast ||
                compsetRow == thirdLast
              ) {
                compareRoomNameDiv.className = "compareRoomNameDiv";
                currentRoomNameElement.className = "upTooltip";
                prevRoomNameElement.className = "upTooltip";
                roomAndRateType.className = "singleRoomNameEx";
              } else {
                compareRoomNameDiv.className = "";
                currentRoomNameElement.className = "orignalTooltip";
                prevRoomNameElement.className = "orignalTooltip";
                roomAndRateType.className = "roomToolTip";
              }

              tooltip.innerHTML = ans.toString();
              hoverText.className = "prevTooltip";

              const marketDemandPickup = rowProps.marketDemand;
              let compareDate = moment(marketDemandPickup).format("DD/MM");
              if (compsetV == 0 || compsetV === undefined) {
                currentRoomNameElement.innerText = "";
                prevRoomNameElement.innerText = "";
                roomAndRateType.innerHTML = "";
              } else if (compareOtaRates == 0 || compareOtaRates == undefined) {
                roomAndRateType.innerHTML = otaRoomName
                  ? `${otaRoomName}`
                  : "NA";
                currentRoomNameElement.innerText = "";
                prevRoomNameElement.innerText = "";
                // roomAndRateType.className = "roomToolTip";
              } else if (
                otaRoomNameAndRoomType !== compareOtaRoomNameAndRoomType
              ) {
                flag.className = "otaRowFlag";
                flag.innerHTML = "&#9666";
                currentRoomNameElement.innerHTML = `&#8226; Today: ${compsetV} &nbsp&nbsp&nbsp - ${otaRoomNameAndRoomType}`;
                prevRoomNameElement.innerHTML = `&#8226; ${compareDate}: ${compareOtaRates} &nbsp&nbsp&nbsp - ${compareOtaRoomNameAndRoomType}`;
              } else {
                roomAndRateType.innerHTML = otaRoomName
                  ? `${otaRoomName}`
                  : "NA";
              }

              if (ans < 0) {
                tooltip.className = "otaCompareDataLess";
                tooltip.innerHTML = ans.toString();
              } else if (
                ans == 0 ||
                compareOtaRates == 0 ||
                compareOtaRates == undefined
              ) {
                tooltip.innerHTML = "";
              } else if (compsetV === undefined) {
                tooltip.innerHTML = "";
              } else {
                tooltip.className = "otaCompareDataMore";
                tooltip.innerHTML = `+${ans.toString()}`;
              }

              // console.log(lastCol);

              s.appendChild(tooltip);
              s.appendChild(flag);
              s.appendChild(hoverText);

              hoverText.appendChild(compareRoomNameDiv);
              compareRoomNameDiv.appendChild(currentRoomNameElement);
              compareRoomNameDiv.appendChild(prevRoomNameElement);
              hoverText.appendChild(roomAndRateType);
            }
          }
        }
      };

      const options = [
        { text: "1 Guest RO", value: "oneGuestRO" },
        { text: "1 Guest BB", value: "oneGuestBB" },
        { text: "2 Guest RO", value: "RO" },
        { text: "2 Guest BB", value: "BB" },
      ];
      options.forEach((optionText) => {
        const option = document.createElement("option");
        option.className = "otaOptions";
        option.value = optionText.value;
        option.text = optionText.text;
        dropdownContent.appendChild(option);
      });
      dropdownContent.addEventListener("click", listener);

      let data = undefined;

      changedValue !== undefined && listener(data);

      div.appendChild(button);
      div.appendChild(dropdownContent);

      button.addEventListener("click", function () {
        if (dropdownContent.style.display === "none") {
          dropdownContent.style.display = "inline-block";
        } else {
          dropdownContent.style.display = "none";
        }
      });

      // Close dropdown when clicking outside of it
      document.addEventListener("click", function (event: any) {
        if (
          event.target.className !== "dropbtn-ota" &&
          event.target.className !== "overrideSpan"
        ) {
          dropdownContent.style.display = "none";
        }
      });

      const label3 = document.createElement("span");
      label3.innerHTML = `&nbsp | ${value}`;
      div.appendChild(label3);

      TD.appendChild(div);
      TD.className = applyCellPropertiesClassName(cellProperties);
      return TD;
    }

    if (rowProp.type == "rewards") {
      TD.className += " margin-left-10px";
    }

    if (rowProp.type === rowType.ratePlan) {
      let r = document.createElement("span");
      r.innerText = "(Set Rate)";
      r.style.color = "red";
      const label3 = document.createElement("span");
      label3.innerHTML = `${value} &nbsp `;
      TD.appendChild(label3);
      rowProp.defaultRate !== 0 && TD.appendChild(r);
      TD.className += " margin-left-20px";
      return TD;
    }

    renderers.TextRenderer.apply(null, [
      instance,
      TD,
      row,
      0,
      prop,
      value,
      cellProperties,
    ]);
  }

  return TD;
}

export function applyCellPropertiesClassName(cellProperties: any): string {
  return (
    typeof cellProperties.className === "object"
      ? cellProperties.className.join(" ")
      : cellProperties.className
  )!;
}
