import { createTheme } from "@material-ui/core";

const rtTheme: any = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        width: "calc(33% - 8px)",
        float: "left",
        marginLeft: "10px",
        marginTop: "10px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
      },
    },
    MuiTypography: {
      root: {
        float: "left",
        width: "100%!important",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
        width: 230,
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#fce8e6",
          color: "#d93025",
          fontWeight: "bolder",
        },
      },
    },
    MuiTableCell: {
      root: {
        textAlign: "center",
      },
    },
  },
});

export { rtTheme };
