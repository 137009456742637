import { API } from "aws-amplify";

export class AdminApi {
  static async getHotelConfig(hotelId: number): Promise<any> {
    return API.get("yield", `/hotel/${hotelId}`, null);
  }

  static async getUsersHotelsMeta(): Promise<any> {
    return API.get("yield", `/hotels`, null);
  }
}
