import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  overrides: {
    MuiAppBar: {
      root: {
        zIndex: 0,
      },
      colorPrimary: {
        backgroundColor: "#B7D1E2",
        color: "black",
      },
    },
    MuiTab: {
      wrapper: {
        fontWeight: "bold",
      },
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
  },
});

export interface IDashboardState {
  dateFrom: Date;
  dateTo: Date;
  yieldSheetPending: boolean;
  publishDropdownShown: boolean;
  selectedHotel: any;
  sheetView: any;
  isEdited: boolean;
  counter: number;
  openBulkUpdate: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      key={`simple-tabpanel-${index}`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
