import { createTheme } from "@material-ui/core";

import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";

const rtTheme: any = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        height: 70,
        width: "95%",
        float: "left",
        margin: "10px",
        fontSize: "1.1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
        padding: "8px 15px 15px 15px",
        lineHeight: "50px",
        display: "flex",
      },
    },
    MuiTypography: {
      root: {
        lineHeight: "50px!important",
        width: "100%",
        float: "left",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiIconButton: {
      root: {
        padding: 2,
        marginRight: 0,
      },
    },
  },
});

const rpTheme: any = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        display: "inline-block",
        backgroundColor: "#e0e0e0",
        paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "100%!important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },
    MuiCard: {
      root: {
        height: 80,
        width: "80%",
        float: "left",
        marginLeft: "8px",
        marginTop: "8px",
        fontSize: "1em",
      },
    },
    MuiCardContent: {
      root: {
        fontSize: "1em",
        padding: 0,
        paddingLeft: 15,
        lineHeight: "50px",
      },
    },
    MuiTypography: {
      root: {
        lineHeight: "50px!important",
        width: "calc(100% - 90px)!important",
        float: "left",
      },
    },
    MuiTextField: {
      root: {
        display: "inline-block",
      },
    },
    MuiSvgIcon: {
      root: {
        float: "right",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-bock",
      },
    },
    MuiIconButton: {
      root: {
        padding: 2,
        marginRight: 0,
      },
    },
  },
});

export interface State {
  isRPPanelOpened: boolean;
  isDeleteRpDialogOpened: boolean;
  isDeleteRtDialogOpened: boolean;
  rateplanData: any;
  hotel: any;
  rpToDelete: any;
  rtToDelete: any;
  isRPPanelNew: boolean;
  roomData: any;
  isRTPanelOpened: boolean;
  isRTPanelNew: boolean;
  unEditable: boolean;
  unEditableRP: boolean;
  isOverflow: boolean[];
  isOverflowRT: boolean[];
  ratePlanArray: any;
  roomTypeData: any;
  counter: number;
  searchInput: any;
  searchDropDown: any;
  isDraging: boolean;
  rtUpdatedStatus: boolean;
  rpUpdatedStatus: boolean;
  rtUpdateData: any;
  previousLocation: string;
  key: number;
  setRateFilter: string;
  searchModeOfSale: string;
}

export interface IRoomConfigProps {
  hotel: any;
  currentUser: string;
  onHotelUpdate: (hotel: any) => void;
  adminUser: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: Partial<MenuPropsType> = {
  variant: "menu",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "inherit",
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};

export { rpTheme, rtTheme, MenuProps };
