import { Auth } from "aws-amplify";
import { SignIn as AmplifySignIn } from "aws-amplify-react";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { MouseEvent } from "react";
import * as Yup from "yup";
import { AuthState, IAuthData } from "../../models/auth.models";
import { inject, observer } from "mobx-react";
import handleEmailValidation from "../utils/emailValidator";

let signInLogo: string;
import(`assets/images/${process.env.REACT_APP_STAGE}/revanista-logo.png`).then(
  (module) => {
    signInLogo = module.default;
  },
);
export function resetFormError(
  setErrors: (errors: { [key: string]: string }) => void,
) {
  setErrors({}); // Reset errors to an empty object
}
interface ISignInProps {
  authData: IAuthData;
  authState: AuthState;
  onAuthStateChange: (next: any, data: any) => void;
  onAuthEvent: (next: any, data: any) => void;
}

interface ISignInState {
  pending: boolean;
  delivery?: any;
}

const SignupSchema = Yup.object().shape({
  password: Yup.string().required("Please provide your password"),
});
class FormikUtils {
  static getFormikFieldClasses(
    formState: FormikProps<IAuthData>, // Formik Props
    fieldName: keyof IAuthData, // Field name from IAuthData
    size?: "sm" | "lg", // Optional size class
  ): string {
    const baseClass = "form-control";
    const sizeClass = size ? `form-control-${size}` : "";
    const validationClass = !!(
      formState.errors[fieldName] && formState.touched[fieldName]
    )
      ? "is-invalid"
      : "";

    return `${baseClass} ${sizeClass} ${validationClass}`.trim();
  }
}
class SignIn extends AmplifySignIn<ISignInProps, ISignInState> {
  static defaultProps: ISignInProps = {
    authData: {},
    authState: "signIn",
    onAuthStateChange: (next: any, data: any) => null,
    onAuthEvent: (next: any, data: any) => null,
  };

  constructor(props: ISignInProps) {
    super(props);
    this.state = {
      pending: false,
    };
  }

  onSignIn = async (
    { username = "", password }: IAuthData,
    actions: FormikActions<IAuthData>,
  ) => {
    this.setState({ pending: true });

    Auth.signIn(username.toLowerCase(), password)
      .then((user) => {
        console.log(user);
        if (
          user.challengeName === "SMS_MFA" ||
          user.challengeName === "SOFTWARE_TOKEN_MFA"
        ) {
          this.changeState("confirmSignIn", user);
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.changeState("requireNewPassword", user);
        } else if (user.challengeName === "MFA_SETUP") {
          this.changeState("TOTPSetup", user);
        } else {
          this.checkContact(user);
        }
      })
      .catch((err) => {
        if (err.code === "UserNotConfirmedException") {
          this.changeState("confirmSignUp", { username });
        } else if (err.code === "PasswordResetRequiredException") {
          this.changeState("resetPassword", null);
        } else {
          actions.setStatus({ error: err.message || err });
        }
      })
      .then(() => {
        this.setState({ pending: false });
      });
  };

  onForgotPassword = (e: MouseEvent) => {
    e.preventDefault();
    this.changeState("forgotPassword", null);
  };

  checkContact(user: IAuthData) {
    Auth.verifiedContact(user).then(() => {
      this.changeState("signedIn", user);
    });
  }

  validateEmail(value: any) {
    let error;

    const validateAdmin = handleEmailValidation(value);
    if (!value) {
      error = "Please provide your email";
    } else if (validateAdmin === false) {
      error = "Please provide a correct email address";
    }
    return error;
  }

  showComponent() {
    const initialFormValues: IAuthData = {
      username: "",
      password: "",
    };

    return (
      <div className="row mt-4">
        <Formik
          initialValues={initialFormValues}
          onSubmit={this.onSignIn}
          validationSchema={SignupSchema}
        >
          {(formState) => (
            <Form
              onChange={() => resetFormError(formState.setErrors)}
              className="col-sm-4 offset-sm-4 mt-4"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="card">
                <fieldset
                  className="card-body p-5"
                  disabled={this.state.pending}
                >
                  <div className="form-group">
                    <img
                      className="img-fluid"
                      src={signInLogo}
                      alt="Revanista Logo"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="required">
                      Email
                    </label>
                    <Field
                      type="text"
                      name="username"
                      id="email"
                      className={FormikUtils.getFormikFieldClasses(
                        formState,
                        "username",
                        "lg",
                      )}
                      validate={this.validateEmail}
                      placeholder="Email"
                    />
                    <div className="invalid-feedback">
                      {formState.errors.username}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="password" className="required">
                      Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      className={FormikUtils.getFormikFieldClasses(
                        formState,
                        "password",
                        "lg",
                      )}
                      placeholder="Password"
                    />
                    <div className="invalid-feedback">
                      {formState.errors.password}
                    </div>
                  </div>

                  {formState.status && formState.status.error ? (
                    <p className="text-danger">{formState.status.error}</p>
                  ) : (
                    ""
                  )}
                  <button type="submit" className="btn btn-lg btn-primary mt-4">
                    Login with email
                  </button>
                  <p className="text-left mt-3">
                    <a href="#" onClick={this.onForgotPassword}>
                      Forgot password?
                    </a>
                  </p>
                </fieldset>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const SignInObserver = inject("appStore")(observer(SignIn));
export { SignInObserver as SignIn };
