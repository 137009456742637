import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { Component } from "react";
import "./onboardHotel.scss";
import SectionTitle from "../elements/sectionTitle";
import { toast } from "react-toastify";
import { YieldSheetService } from "../services";
import { rtTheme } from "../onboard-hotel/defs";
import InfoIcon from "@material-ui/icons/Info";
import { Autocomplete } from "@material-ui/lab";
import Popup from "../../reusable-components/popup/popup";
import { OtaApi } from "../../api/ota";
import {
  MuiThemeProvider,
  TextField,
  Grid,
  Tooltip,
  FormControl,
  Button,
} from "@material-ui/core";

interface State {
  startDate: string;
  inputData: any;
  validateHotelName: boolean;
  validateHotelNameMsg: any;
  validateShortName: boolean;
  validateShortNameMsg: any;
  validateContractType: boolean;
  validateUsingRewards: boolean;
  validateTotalNumberOfRooms: boolean;
  validateDefaultCompetitiveSetType: boolean;
  validateIsRateMatrix: boolean;
  validateDefaultPrice: boolean;
  validateOWSDifferential: boolean;
  validateInventoryComesDirectlyFromPMSToChannelManager: boolean;
  validateOTAHotelId: boolean;
  validateChannelManager: boolean;
  warning3: boolean;
  hotel: any;
  submitBtnStatus: boolean;
  spinnerStatus: boolean;
  buttonClickstatus: boolean;
  spinnerTextImage: boolean;
  validateTotalNumberOfRoomsMsg: any;
  validateDefaultPriceMsg: any;
  validateOWSDifferentialMsg: any;
  validateMaximumRate: boolean;
  validateMaximumRateMsg: any;
  validateMinimumRate: boolean;
  validateMinimumRateMsg: any;
  validateOTAHotelIdMsg: any;
  validateHotelCode: boolean;
  validateHotelCodeMsg: any;
  validateUsername: boolean;
  validateUsernameMsg: any;
  validateCurrencyCode: boolean;
  validateCurrencyCodeMsg: any;
  compsetHotelNameList: any;
  value: any;
  newHotelNameValue: any;
  hover: boolean;
}
export default class OnboardHotel extends Component {
  readonly state: State = {
    inputData: {
      InventoryComesDirectlyFromPMSToChannelManager: "",
      PMSProvider: "none",
      CurrencyCode: "EUR",
    },
    startDate: moment().format("YYYY-MM-DD"),
    compsetHotelNameList: [],
    value: {},
    newHotelNameValue: {},
    validateHotelName: false,
    validateHotelNameMsg: "",
    validateShortName: false,
    validateShortNameMsg: "",
    validateContractType: true,
    validateUsingRewards: true,
    validateTotalNumberOfRooms: false,
    validateDefaultCompetitiveSetType: false,
    validateIsRateMatrix: false,
    validateDefaultPrice: false,
    validateOWSDifferential: false,
    validateInventoryComesDirectlyFromPMSToChannelManager: true,
    validateOTAHotelId: false,
    validateChannelManager: true,
    validateCurrencyCode: true,
    validateCurrencyCodeMsg: "",
    warning3: false,
    hotel: {},
    submitBtnStatus: false,
    spinnerStatus: false,
    buttonClickstatus: false,
    spinnerTextImage: false,
    validateTotalNumberOfRoomsMsg: "",
    validateDefaultPriceMsg: "",
    validateOWSDifferentialMsg: "",
    validateMaximumRate: true,
    validateMaximumRateMsg: "",
    validateMinimumRate: true,
    validateMinimumRateMsg: "",
    validateOTAHotelIdMsg: "",
    validateHotelCode: false,
    validateHotelCodeMsg: "",
    validateUsername: false,
    validateUsernameMsg: "",
    hover: false,
  };

  async componentDidMount() {
    try {
      let compsetHotelNameList = await OtaApi.getOtaHotelCompleteList();
      this.setState({
        compsetHotelNameList: compsetHotelNameList,
      });
    } catch (err) {
      throw err;
    }
  }

  handleChange = (e: any) => {
    const { name, value, type } = e.target;
    this.setState({ inputData: { ...this.state.inputData, [name]: value } });

    console.log(this.state.inputData);

    switch (name) {
      case "ChannelManager":
        if (value === "guestline") {
          this.setState({
            inputData: {
              ...this.state.inputData,
              ["PMSProvider"]: "guestline",
              ["ChannelManager"]: "guestline",
              ["PMSInventoryType"]: "live",
            },
            validateChannelManager: true,
          });
        } else if (!value) {
          this.setState({ validateChannelManager: false });
        } else {
          this.setState({
            inputData: {
              ...this.state.inputData,
              ["PMSProvider"]: "none",
              ["ChannelManager"]: value,
              ["PMSInventoryType"]: "manual",
            },
            validateChannelManager: true,
          });
        }
        break;
      case "ShortName":
        if (!value) {
          this.setState({ validateShortName: true });
          this.setState({ validateShortNameMsg: "Mandatory field" });
        } else if (!value.match(/^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/)) {
          this.setState({ validateShortName: true });
          this.setState({
            validateShortNameMsg: "Only alphabets and spaces allowed",
          });
        } else {
          this.setState({ validateShortName: false });
          this.setState({ validateShortNameMsg: "" });
        }
        break;
      case "HotelName":
        if (!value) {
          this.setState({ validateHotelName: true });
          this.setState({ validateHotelNameMsg: "Mandatory field" });
        }
        // else if (!value.match(/^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/)) {
        else if (!value.match(/^[a-zA-Z\s'&]+$/)) {
          this.setState({ validateHotelName: true });
          this.setState({
            validateHotelNameMsg:
              "Only alphabets, spaces, and following special characters allowed:  &  '",
          });
        } else {
          this.setState({ validateHotelName: false });
          this.setState({ validateHotelNameMsg: "" });
        }
        break;

      case "HotelCode":
        if (!value) {
          this.setState({ validateHotelCode: true });
          this.setState({
            validateHotelCodeMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateHotelCode: false });
          this.setState({ validateHotelCodeMsg: "" });
        }
        break;
      case "DefaultCompetitiveSetDisplayed":
        if (!value) {
          this.setState({ validateDefaultCompetitiveSetType: true });
        } else {
          this.setState({ validateDefaultCompetitiveSetType: false });
        }
        break;
      case "isRateMatrix":
        if (!value) {
          this.setState({ validateIsRateMatrix: true });
        } else {
          this.setState({ validateIsRateMatrix: false });
        }
        break;

      case "Username":
        if (!value) {
          this.setState({ validateUsername: true });
          this.setState({ validateUsernameMsg: "Mandatory field" });
        } else {
          this.setState({ validateUsername: false });
          this.setState({ validateUsernameMsg: "" });
        }
        break;
      case "ContractType":
        if (!value) {
          this.setState({ validateContractType: false });
        } else {
          this.setState({ validateContractType: true });
        }
        break;
      case "CurrencyCode":
        if (!value) {
          this.setState({ validateCurrencyCode: false });
          this.setState({
            validateCurrencyCodeMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateCurrencyCode: true });
          this.setState({ validateCurrencyCodeMsg: "" });
        }
        break;
      case "UsingRewards":
        if (!value) {
          this.setState({ validateUsingRewards: false });
        } else {
          this.setState({ validateUsingRewards: true });
        }
        break;
      case "DefaultPrice":
        if (!value) {
          this.setState({ validateDefaultPrice: true });
          this.setState({
            validateDefaultPriceMsg: "Mandatory field",
          });
        } else if (value > 10000000) {
          this.setState({ validateDefaultPrice: true });
          this.setState({
            validateDefaultPriceMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateDefaultPrice: true });
          this.setState({
            validateDefaultPriceMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateDefaultPrice: false });
          this.setState({
            validateDefaultPriceMsg: "",
          });
        }
        break;
      case "OWSDifferential":
        if (!value) {
          this.setState({ validateOWSDifferential: true });
          this.setState({
            validateOWSDifferentialMsg: "Mandatory field",
          });
        } else if (value > 10000) {
          this.setState({ validateOWSDifferential: true });
          this.setState({
            validateOWSDifferentialMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateOWSDifferential: true });
          this.setState({
            validateOWSDifferentialMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateOWSDifferential: false });
          this.setState({
            validateOWSDifferentialMsg: "",
          });
        }
        break;
      case "InventoryComesDirectlyFromPMSToChannelManager":
        if (!value) {
          this.setState({
            validateInventoryComesDirectlyFromPMSToChannelManager: false,
          });
        } else {
          this.setState({
            validateInventoryComesDirectlyFromPMSToChannelManager: true,
          });
        }
        break;
      case "TotalNumberOfRooms":
        if (!value) {
          this.setState({ validateTotalNumberOfRooms: true });

          this.setState({
            validateTotalNumberOfRoomsMsg: "Mandatory field",
          });
        } else if (value > 10000) {
          this.setState({ validateTotalNumberOfRooms: true });
          this.setState({
            validateTotalNumberOfRoomsMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateTotalNumberOfRooms: true });
          this.setState({
            validateTotalNumberOfRoomsMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateTotalNumberOfRooms: false });
          this.setState({
            validateTotalNumberOfRoomsMsg: "",
          });
        }
        break;
      case "OTAHotelId":
        if (!value) {
          this.setState({ validateOTAHotelId: true });
          this.setState({
            validateOTAHotelIdMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateOTAHotelId: false });
          this.setState({
            validateOTAHotelIdMsg: "",
          });
        }
        break;
      case "MaximumRate":
        if (!value) {
          this.setState({ validateMaximumRate: true });
          this.setState({
            validateMaximumRateMsg: "",
          });
        } else if (value > 10000000) {
          this.setState({ validateMaximumRate: false });
          this.setState({
            validateMaximumRateMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateMaximumRate: false });
          this.setState({
            validateMaximumRateMsg:
              "Negative number not allowed in Maximum Value",
          });
        } else {
          this.setState({ validateMaximumRate: true });
          this.setState({
            validateMaximumRateMsg: "",
          });
        }
        break;
      case "MinimumRate":
        if (!value) {
          this.setState({ validateMinimumRate: true });
          this.setState({
            validateMinimumRateMsg: "",
          });
        } else if (value > 10000000) {
          this.setState({ validateMinimumRate: false });
          this.setState({
            validateMinimumRateMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateMinimumRate: false });
          this.setState({
            validateMinimumRateMsg:
              "Negative number not allowed in Minimum Rate",
          });
        } else if (
          parseInt(this.state.inputData.MinimumRate) >=
          parseInt(this.state.inputData.MaximumRate)
        ) {
          this.setState({ validateMinimumRate: false });
          this.setState({
            validateMinimumRateMsg:
              "Minimum Rate can't be greater then Maximum Rate",
          });
        } else {
          this.setState({ validateMinimumRate: true });
          this.setState({
            validateMinimumRateMsg: "",
          });
        }
        break;
    }
  };

  formValidation = async (e: any) => {
    var key = e.charCode;
    if (key === "Enter") {
      alert("no enter");
      return;
    }
    e.preventDefault();
    if (Object.values(this.state.inputData).length != 0) {
      if (this.state.inputData.ChannelManager === "guestline") {
        if (
          this.state.inputData.HotelName &&
          this.state.inputData.ShortName &&
          this.state.inputData.ContractType &&
          this.state.inputData.DefaultCompetitiveSetDisplayed &&
          this.state.inputData.UsingRewards &&
          this.state.inputData.TotalNumberOfRooms &&
          this.state.inputData.DefaultPrice &&
          this.state.inputData.OWSDifferential &&
          this.state.newHotelNameValue &&
          this.state.inputData.ChannelManager &&
          this.state.inputData.HotelCode &&
          this.state.inputData.CurrencyCode
        ) {
          //if validation success
          if (!this.state.inputData.HotelName.match(/^[a-zA-Z\s'&]+$/)) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (
            !this.state.inputData.ShortName.match(
              /^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/,
            )
          ) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.TotalNumberOfRooms > 10000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }

          if (this.state.inputData.TotalNumberOfRooms < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }

          if (this.state.inputData.DefaultPrice > 10000000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.DefaultPrice < 0) {
            toast(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.OWSDifferential > 10000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.OWSDifferential < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.MinimumRate > 10000000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.MinimumRate < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.MaximumRate < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (Object.keys(this.state.newHotelNameValue).length === 0) {
            toast.error(`Please check highlighted field(s)`);
            this.setState({ validateOTAHotelId: true });
            this.setState({
              validateOTAHotelIdMsg: "Mandatory field",
            });
            return false;
          }
          if (
            parseInt(this.state.inputData.MinimumRate) >=
            parseInt(this.state.inputData.MaximumRate)
          ) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          return true;
        } else {
          if (!this.state.inputData.HotelName) {
            this.setState({ validateHotelName: true });
            this.setState({
              validateHotelNameMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.DefaultCompetitiveSetDisplayed) {
            this.setState({ validateDefaultCompetitiveSetType: true });
          }
          if (!this.state.inputData.isRateMatrix) {
            this.setState({ validateIsRateMatrix: true });
          }
          if (!this.state.inputData.ShortName) {
            this.setState({ validateShortName: true });
            this.setState({
              validateShortNameMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.ContractType) {
            this.setState({ validateContractType: false });
          }
          if (!this.state.inputData.CurrencyCode) {
            this.setState({ validateCurrencyCode: false });
            this.setState({
              validateCurrencyCodeMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.UsingRewards) {
            this.setState({ validateUsingRewards: false });
          }
          if (!this.state.inputData.TotalNumberOfRooms) {
            this.setState({ validateTotalNumberOfRooms: true });
            this.setState({
              validateTotalNumberOfRoomsMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.HotelCode) {
            this.setState({ validateHotelCode: true });
            this.setState({
              validateHotelCodeMsg: "Mandatory field",
            });
          }

          if (!this.state.inputData.DefaultPrice) {
            this.setState({ validateDefaultPrice: true });
            this.setState({
              validateDefaultPriceMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.OWSDifferential) {
            this.setState({ validateOWSDifferential: true });
            this.setState({
              validateOWSDifferentialMsg: "Mandatory field",
            });
          }
          if (Object.keys(this.state.newHotelNameValue).length === 0) {
            this.setState({ validateOTAHotelId: true });
            this.setState({
              validateOTAHotelIdMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.ChannelManager) {
            this.setState({ validateChannelManager: false });
          } else {
            this.setState({ validateChannelManager: true });
          }
          toast.error(`Please check highlighted field(s)`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      } else {
        if (
          this.state.inputData.HotelName &&
          this.state.inputData.ShortName &&
          this.state.inputData.ContractType &&
          this.state.inputData.UsingRewards &&
          this.state.inputData.TotalNumberOfRooms &&
          this.state.inputData.DefaultPrice &&
          this.state.inputData.OWSDifferential &&
          this.state.inputData.InventoryComesDirectlyFromPMSToChannelManager &&
          this.state.newHotelNameValue &&
          this.state.inputData.ChannelManager &&
          this.state.inputData.Username &&
          this.state.inputData.HotelCode &&
          this.state.inputData.CurrencyCode
        ) {
          //if validation success
          if (!this.state.inputData.HotelName.match(/^[a-zA-Z\s'&]+$/)) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (
            !this.state.inputData.ShortName.match(
              /^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/,
            )
          ) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.TotalNumberOfRooms > 10000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }

          if (this.state.inputData.TotalNumberOfRooms < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }

          if (this.state.inputData.DefaultPrice > 10000000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.DefaultPrice < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.OWSDifferential > 10000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.OWSDifferential < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.MinimumRate > 10000000) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.MinimumRate < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (this.state.inputData.MaximumRate < 0) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          if (Object.keys(this.state.newHotelNameValue).length === 0) {
            toast.error(`Please check highlighted field(s)`);
            this.setState({ validateOTAHotelId: true });
            this.setState({
              validateOTAHotelIdMsg: "Mandatory field",
            });
            return false;
          }
          if (
            parseInt(this.state.inputData.MinimumRate) >=
            parseInt(this.state.inputData.MaximumRate)
          ) {
            toast.error(`Please check highlighted field(s)`);
            return false;
          }
          return true;
        } else {
          if (!this.state.inputData.HotelName) {
            this.setState({ validateHotelName: true });
            this.setState({
              validateHotelNameMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.DefaultCompetitiveSetDisplayed) {
            this.setState({ validateDefaultCompetitiveSetType: true });
          }
          if (!this.state.inputData.isRateMatrix) {
            this.setState({ validateIsRateMatrix: true });
          }
          if (!this.state.inputData.ShortName) {
            this.setState({ validateShortName: true });
            this.setState({
              validateShortNameMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.ContractType) {
            this.setState({ validateContractType: false });
          }
          if (!this.state.inputData.CurrencyCode) {
            this.setState({ validateCurrencyCode: false });
            this.setState({
              validateCurrencyCodeMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.UsingRewards) {
            this.setState({ validateUsingRewards: false });
          }
          if (!this.state.inputData.TotalNumberOfRooms) {
            this.setState({ validateTotalNumberOfRooms: true });
            this.setState({
              validateTotalNumberOfRoomsMsg: "Mandatory field",
            });
          }

          if (!this.state.inputData.DefaultPrice) {
            this.setState({ validateDefaultPrice: true });
            this.setState({
              validateDefaultPriceMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.OWSDifferential) {
            this.setState({ validateOWSDifferential: true });
            this.setState({
              validateOWSDifferentialMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.Username) {
            this.setState({ validateUsername: true });
            this.setState({
              validateUsernameMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.HotelCode) {
            this.setState({ validateHotelCode: true });
            this.setState({
              validateHotelCodeMsg: "Mandatory field",
            });
          }
          if (
            !this.state.inputData.InventoryComesDirectlyFromPMSToChannelManager
          ) {
            this.setState({
              validateInventoryComesDirectlyFromPMSToChannelManager:
                this.state.inputData.ChannelManager === "guestline"
                  ? true
                  : false,
            });
          }
          if (Object.keys(this.state.newHotelNameValue).length === 0) {
            this.setState({ validateOTAHotelId: true });
            this.setState({
              validateOTAHotelIdMsg: "Mandatory field",
            });
          }
          if (!this.state.inputData.ChannelManager) {
            this.setState({ validateChannelManager: false });
          } else {
            this.setState({ validateChannelManager: true });
          }
          toast.error(`Please check highlighted field(s)`);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }
      //return false;
    } else {
      //if validation failed
      toast.error(`Hotel Onboarding Failed`);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  validate = (data: any) => {
    const error = {
      UsingRewards: "",
      DefaultPrice: "",
      CM: "",
      OTAHotelId: "",
    };
    if (!data.UsingRewards) {
      error.UsingRewards = "Using Rewards";
    }
    if (!data.DefaultPrice) {
      error.DefaultPrice = "Default Base Rate";
    }
    if (!data.ChannelManager) {
      error.CM = "Channel Manager";
    }

    if (!data.OTAHotelId) {
      error.OTAHotelId = "OTA Hotel ID";
    }
    return error;
  };

  handleOnSubmit = async (e: any) => {
    //Form validation
    let response = await this.formValidation(e);

    if (response === true) {
      //Loading spinner logic
      this.setState({
        submitBtnStatus: true, //Disable submit button
        spinnerStatus: true, //Activate spinner
        spinnerTextImage: true, //Display spinner text & image
      });

      //Disabled scroll
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflow = "hidden";

      //Create hotel onboarding object
      this.state.hotel = {
        meta: {
          defaultBar: this.state.inputData.DefaultPrice,
          hasRewards:
            this.state.inputData.UsingRewards === "true" ? true : false,
          globalRestriction: {
            value: 6,
            key: "max_days",
          },
          isPublishingInventory:
            this.state.inputData.ChannelManager === "guestline"
              ? false
              : this.state.inputData
                  .InventoryComesDirectlyFromPMSToChannelManager,
          totalRooms: this.state.inputData.TotalNumberOfRooms,
        },

        info: {
          level: this.state.inputData.ContractType,
          contactEmails: [],
          name: this.state.inputData.ShortName,
          fullName: this.state.inputData.HotelName,
          currencyCode: this.state.inputData.CurrencyCode,
        },
        hotelOnboardDate: this.state.startDate,
        pms: {
          id: this.state.inputData.PMSProvider,
          inventoryType: this.state.inputData.PMSInventoryType,
          apiSettings: {
            hotelCode: this.state.inputData.PMSHotelCode,
            providerKey: this.state.inputData.PMSProviderKey,
          },
        },
        cm: {
          APISettings: {
            providerId: "REVANISTA",
            hotelId: this.state.inputData.HotelCode,
            flexTable: this.state.inputData.flexTable,
          },
          id: this.state.inputData.ChannelManager,
          credentials: {
            userId: this.state.inputData.Username
              ? this.state.inputData.Username
              : "",
            password: this.state.inputData.Password,
          },
          channelConfig: {
            ows: {
              formula: "BR-" + this.state.inputData.OWSDifferential,
              SeasonnalConfig: {
                formulas: [],
              },
            },
          },
        },
        ota: {
          hotelId: this.state.newHotelNameValue["id"],
          subscriptionId: this.state.newHotelNameValue["subscription_id"],
          defaultCompsetType:
            this.state.inputData.DefaultCompetitiveSetDisplayed,
        },
        suggestionParams: {
          matrixes: [
            {
              bands: [],
            },
          ],
          isEnabled: this.state.inputData.isRateMatrix,
        },
        ratePlans: {},
        roomTypes: {},
      };

      //Process api call to store hotel data
      try {
        await YieldSheetService.revanista.hotel.addNewHotel({
          hotel: this.state.hotel,
          start: this.state.startDate,
        });
        this.setState({
          warning3: true,
          submitBtnStatus: false,
          spinnerStatus: true,
          spinnerTextImage: false,
        });
        document.body.style.overflow = "auto";
      } catch (err) {
        this.setState({
          submitBtnStatus: false,
          spinnerStatus: false,
        });
        document.body.style.overflow = "auto";
        toast.error("Failed to onboard hotel");
      }
    }
  };

  //Cancel button click changes to redirect on  dashboard
  cancelButtonClick = () => {
    this.setState({ buttonClickstatus: true });
  };
  // Display of error message on onBlur
  onBlurOfInputFields = (event: any) => {
    let fieldName = event.target.name;
    let value = event.target.value;
    switch (fieldName) {
      case "ShortName":
        if (!value) {
          this.setState({ validateShortName: true });
          this.setState({ validateShortNameMsg: "Mandatory field" });
        } else if (!value.match(/^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/)) {
          this.setState({ validateShortName: true });
          this.setState({
            validateShortNameMsg: "Only alphabets and spaces allowed",
          });
        } else {
          this.setState({ validateShortName: false });
          this.setState({ validateShortNameMsg: "" });
        }
        break;
      case "HotelName":
        if (!value) {
          this.setState({ validateHotelName: true });
          this.setState({ validateHotelNameMsg: "Mandatory field" });
        }
        // else if (!value.match(/^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/)) {
        else if (!value.match(/^[a-zA-Z\s'&]+$/)) {
          this.setState({ validateHotelName: true });
          this.setState({
            validateHotelNameMsg:
              "Only alphabets, spaces, and following special characters allowed:  &  '",
          });
        } else {
          this.setState({ validateHotelName: false });
          this.setState({ validateHotelNameMsg: "" });
        }
        break;
      case "DefaultCompetitiveSetDisplayed":
        if (!value) {
          this.setState({ validateHotelName: true });
          this.setState({ validateHotelNameMsg: "Mandatory field" });
        }
        break;
      case "HotelCode":
        if (!value) {
          this.setState({ validateHotelCode: true });
          this.setState({
            validateHotelCodeMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateHotelCode: false });
          this.setState({ validateHotelCodeMsg: "" });
        }
        break;

      case "Username":
        if (!value) {
          this.setState({ validateUsername: true });
          this.setState({ validateUsernameMsg: "Mandatory field" });
        } else {
          this.setState({ validateUsername: false });
          this.setState({ validateUsernameMsg: "" });
        }
        break;
      case "ContractType":
        if (!value) {
          this.setState({ validateContractType: false });
        } else {
          this.setState({ validateContractType: true });
        }
        break;
      case "CurrencyCode":
        if (!value) {
          this.setState({ validateCurrencyCode: false });
          this.setState({
            validateCurrencyCodeMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateCurrencyCode: true });
        }
        break;
      case "UsingRewards":
        if (!value) {
          this.setState({ validateUsingRewards: false });
        } else {
          this.setState({ validateUsingRewards: true });
        }
        break;
      case "DefaultPrice":
        if (!value) {
          this.setState({ validateDefaultPrice: true });
          this.setState({
            validateDefaultPriceMsg: "Mandatory field",
          });
        } else if (value > 10000000) {
          this.setState({ validateDefaultPrice: true });
          this.setState({
            validateDefaultPriceMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateDefaultPrice: true });
          this.setState({
            validateDefaultPriceMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateDefaultPrice: false });
          this.setState({
            validateDefaultPriceMsg: "",
          });
        }
        break;
      case "OWSDifferential":
        if (!value) {
          this.setState({ validateOWSDifferential: true });
          this.setState({
            validateOWSDifferentialMsg: "Mandatory field",
          });
        } else if (value > 10000) {
          this.setState({ validateOWSDifferential: true });
          this.setState({
            validateOWSDifferentialMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateOWSDifferential: true });
          this.setState({
            validateOWSDifferentialMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateOWSDifferential: false });
          this.setState({
            validateOWSDifferentialMsg: "",
          });
        }
        break;
      case "InventoryComesDirectlyFromPMSToChannelManager":
        if (!value) {
          this.setState({
            validateInventoryComesDirectlyFromPMSToChannelManager: false,
          });
        } else {
          this.setState({
            validateInventoryComesDirectlyFromPMSToChannelManager: true,
          });
        }
        break;
      case "TotalNumberOfRooms":
        if (!value) {
          this.setState({ validateTotalNumberOfRooms: true });

          this.setState({
            validateTotalNumberOfRoomsMsg: "Mandatory field",
          });
        } else if (value > 10000) {
          this.setState({ validateTotalNumberOfRooms: true });
          this.setState({
            validateTotalNumberOfRoomsMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateTotalNumberOfRooms: true });
          this.setState({
            validateTotalNumberOfRoomsMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateTotalNumberOfRooms: false });
          this.setState({
            validateTotalNumberOfRoomsMsg: "",
          });
        }
        break;
      case "OTAHotelId":
        if (!value) {
          this.setState({ validateOTAHotelId: true });
          this.setState({
            validateOTAHotelIdMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateOTAHotelId: false });
          this.setState({
            validateOTAHotelIdMsg: "",
          });
        }
        break;
      case "ChannelManager":
        if (!value) {
          this.setState({ validateChannelManager: false });
        } else {
          this.setState({ validateChannelManager: true });
        }
        break;
      case "MaximumRate":
        if (!value) {
          this.setState({ validateMaximumRate: true });
          this.setState({
            validateMaximumRateMsg: "",
          });
        } else if (value > 10000000) {
          this.setState({ validateMaximumRate: false });
          this.setState({
            validateMaximumRateMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateMaximumRate: false });
          this.setState({
            validateMaximumRateMsg:
              "Negative number not allowed in Maximum Value",
          });
        } else {
          this.setState({ validateMaximumRate: true });
          this.setState({
            validateMaximumRateMsg: "",
          });
        }
        break;
      case "MinimumRate":
        if (!value) {
          this.setState({ validateMinimumRate: true });
          this.setState({
            validateMinimumRateMsg: "",
          });
        } else if (value > 10000000) {
          this.setState({ validateMinimumRate: false });
          this.setState({
            validateMinimumRateMsg: "Maximum Value Exceeded",
          });
        } else if (value < 0) {
          this.setState({ validateMinimumRate: false });
          this.setState({
            validateMinimumRateMsg:
              "Negative number not allowed in Minimum Rate",
          });
        } else if (
          parseInt(this.state.inputData.MinimumRate) >=
          parseInt(this.state.inputData.MaximumRate)
        ) {
          this.setState({ validateMinimumRate: false });
          this.setState({
            validateMinimumRateMsg:
              "Minimum Rate can't be greater then Maximum Rate",
          });
        } else {
          this.setState({ validateMinimumRate: true });
          this.setState({
            validateMinimumRateMsg: "",
          });
        }
        break;
    }
  };

  disableDates = () => {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = today.getDate() + 1;
    mm = today.getMonth() + 1;
    yyyy = today.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  };

  handleOnMouseOver = () => {
    this.setState({ hover: true });
  };

  handleOnMouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    if (this.state.buttonClickstatus) {
      window.location.href = "/";
    }

    return (
      <MuiThemeProvider theme={rtTheme}>
        <Backdrop open={this.state.spinnerStatus}>
          <CircularProgress color="inherit" id="circular-progress" />
        </Backdrop>
        <div className="content">
          <h1 className="h">Onboard Hotel</h1>
          <form
            action=""
            onSubmit={this.handleOnSubmit}
            style={{ overflow: "hidden" }}
          >
            <FormControl>
              <div className="main-div">
                <SectionTitle {...{ value: "Hotel Settings" }} />
                <br />

                <div style={{ flexGrow: "1" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        style={{ width: "100%" }}
                        fullWidth
                        id="outlined-basic"
                        label="Hotel Name"
                        variant="outlined"
                        name="HotelName"
                        onChange={this.handleChange}
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        error={this.state.validateHotelName}
                        inputProps={{ maxLength: 40 }}
                        helperText={this.state.validateHotelNameMsg}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        style={{ width: "100%" }}
                        fullWidth
                        id="outlined-basic"
                        label="Hotel Short Name"
                        variant="outlined"
                        name="ShortName"
                        // required
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        error={this.state.validateShortName}
                        helperText={this.state.validateShortNameMsg}
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        defaultValue=""
                        style={{ width: "100%" }}
                        fullWidth
                        select
                        label="Contract Type"
                        variant="outlined"
                        name="ContractType"
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        onChange={this.handleChange}
                        error={this.state.validateContractType ? false : true}
                        helperText={
                          this.state.validateContractType
                            ? ""
                            : "Mandatory field"
                        }
                      >
                        <option value="Pro">Pro</option>
                        <option value="Plus">Plus</option>
                        <option value="Assist">Assist</option>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        defaultValue=""
                        style={{ width: "100%" }}
                        fullWidth
                        select
                        label="Rewards Site Function"
                        variant="outlined"
                        name="UsingRewards"
                        onChange={this.handleChange}
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        error={this.state.validateUsingRewards ? false : true}
                        helperText={
                          this.state.validateUsingRewards
                            ? ""
                            : "Mandatory field"
                        }
                      >
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        defaultValue=""
                        style={{ width: "100%" }}
                        fullWidth
                        select
                        label="Default Competitive Set Displayed"
                        variant="outlined"
                        name="DefaultCompetitiveSetDisplayed"
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        onChange={this.handleChange}
                        error={this.state.validateDefaultCompetitiveSetType}
                        helperText={
                          this.state.validateDefaultCompetitiveSetType
                            ? "Mandatory field"
                            : ""
                        }
                      >
                        <option value="oneGuestRO">1 Guest RO</option>
                        <option value="oneGuestBB">1 Guest BB</option>
                        <option value="RO">2 Guest RO</option>
                        <option value="BB">2 Guest BB</option>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        type={"number"}
                        style={{ width: "100%" }}
                        fullWidth
                        id="outlined-basic"
                        label="Total Number of Rooms"
                        variant="outlined"
                        name="TotalNumberOfRooms"
                        // required
                        onChange={this.handleChange}
                        inputProps={{ min: 0 }}
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        error={this.state.validateTotalNumberOfRooms}
                        helperText={this.state.validateTotalNumberOfRoomsMsg}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        type="number"
                        style={{ width: "100%" }}
                        fullWidth
                        id="outlined-basic"
                        label="Default Base Rate"
                        variant="outlined"
                        name="DefaultPrice"
                        inputProps={{ min: 0 }}
                        onChange={this.handleChange}
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        error={this.state.validateDefaultPrice}
                        helperText={this.state.validateDefaultPriceMsg}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        type="number"
                        style={{ width: "100%" }}
                        fullWidth
                        id="outlined-basic"
                        label="OWS Differential"
                        variant="outlined"
                        name="OWSDifferential"
                        inputProps={{ min: 0 }}
                        onChange={this.handleChange}
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        error={this.state.validateOWSDifferential}
                        helperText={this.state.validateOWSDifferentialMsg}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        defaultValue=""
                        style={{ width: "100%" }}
                        fullWidth
                        select
                        label="Rate Matrix"
                        variant="outlined"
                        name="isRateMatrix"
                        onChange={this.handleChange}
                        error={this.state.validateIsRateMatrix}
                        helperText={
                          this.state.validateIsRateMatrix
                            ? "Mandatory field"
                            : ""
                        }
                      >
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        defaultValue={this.state.inputData.CurrencyCode}
                        style={{ width: "100%" }}
                        fullWidth
                        select
                        label="Currency"
                        variant="outlined"
                        name="CurrencyCode"
                        onChange={this.handleChange}
                        error={this.state.validateCurrencyCode ? false : true}
                        helperText={this.state.validateCurrencyCodeMsg}
                      >
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                      </TextField>
                    </Grid>
                  </Grid>
                </div>
                <br />
                <SectionTitle {...{ value: "Channel Manager Settings" }} />
                <br />
                <div style={{ flexGrow: "1" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        defaultValue=""
                        style={{ width: "100%" }}
                        fullWidth
                        select
                        label="Channel Manager"
                        variant="outlined"
                        name="ChannelManager"
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        onChange={this.handleChange}
                        error={this.state.validateChannelManager ? false : true}
                        helperText={
                          this.state.validateChannelManager
                            ? ""
                            : "Mandatory field"
                        }
                      >
                        <option value="siteMinder">SiteMinder</option>
                        <option value="channelRush">Channel Rush</option>
                        <option value="guestline">Guestline</option>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}></Grid>
                    <Grid item xs={12} sm={6} md={4}></Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        style={{ width: "100%" }}
                        fullWidth
                        id="outlined-basic"
                        label="Hotel Code"
                        variant="outlined"
                        name="HotelCode"
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 20 }}
                        error={this.state.validateHotelCode}
                        helperText={this.state.validateHotelCodeMsg}
                        onBlur={this.onBlurOfInputFields.bind(this)}
                      />
                    </Grid>

                    {this.state.inputData.ChannelManager !== "guestline" ? (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{ width: "100%" }}
                            fullWidth
                            id="outlined-basic"
                            label="Username"
                            variant="outlined"
                            name="Username"
                            onChange={this.handleChange}
                            inputProps={{ maxLength: 20 }}
                            error={this.state.validateUsername}
                            helperText={this.state.validateUsernameMsg}
                            onBlur={this.onBlurOfInputFields.bind(this)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{ width: "100%" }}
                            fullWidth
                            id="outlined-basic"
                            label="Password"
                            variant="outlined"
                            name="Password"
                            onChange={this.handleChange}
                            inputProps={{ maxLength: 20 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            defaultValue=""
                            style={{ width: "calc(100% - 30px)" }}
                            fullWidth
                            select
                            label="Publish Inventory"
                            variant="outlined"
                            name="InventoryComesDirectlyFromPMSToChannelManager"
                            onChange={this.handleChange}
                            onBlur={this.onBlurOfInputFields.bind(this)}
                            error={
                              this.state
                                .validateInventoryComesDirectlyFromPMSToChannelManager
                                ? false
                                : true
                            }
                            helperText={
                              this.state
                                .validateInventoryComesDirectlyFromPMSToChannelManager
                                ? ""
                                : "Mandatory field"
                            }
                          >
                            <option value="true">Enabled</option>
                            <option value="false">Disabled</option>
                          </TextField>
                          <Tooltip
                            title="If enabled, Revanista will allow to publish availability to Channel Manager. If disabled, property's PMS should be sending availability to Channel Manager."
                            arrow
                            placement="left"
                          >
                            <InfoIcon
                              onMouseOver={this.handleOnMouseOver}
                              onMouseOut={this.handleOnMouseOut}
                              style={{
                                color: this.state.hover ? "blue" : "",
                                marginTop: "14px",
                                marginLeft: "5px",
                                float: "none",
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          style={{ width: "100%" }}
                          fullWidth
                          id="outlined-basic"
                          label="Flex Table"
                          variant="outlined"
                          name="flexTable"
                          onChange={this.handleChange}
                          inputProps={{ maxLength: 20 }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
                <br />
                <SectionTitle {...{ value: "PMS Settings" }} />
                <br />
                <div style={{ flexGrow: "1" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={this.state.inputData.PMSProvider}
                        style={{
                          width: "100%",
                          backgroundColor:
                            this.state.inputData.ChannelManager === "guestline"
                              ? "none"
                              : "#D3D3D3",
                        }}
                        fullWidth
                        select
                        label="PMS Provider"
                        variant="outlined"
                        name="PMSProvider"
                        onBlur={this.onBlurOfInputFields.bind(this)}
                        onChange={this.handleChange}
                        inputProps={{ readOnly: true }}
                      >
                        <option value="hotsoft">HotSoft</option>
                        <option value="guestline">Guestline</option>
                        <option value="none">None</option>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}></Grid>
                    <Grid item xs={12} sm={6} md={4}></Grid>
                    {this.state.inputData.PMSProvider === "hotsoft" && (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{
                              width: "100%",
                              backgroundColor: "#D3D3D3",
                            }}
                            fullWidth
                            id="outlined-basic"
                            label="Hotel Code"
                            variant="outlined"
                            name="PMSHotelCode"
                            onChange={this.handleChange}
                            onBlur={this.onBlurOfInputFields.bind(this)}
                            inputProps={{
                              maxLength: 20,
                              readOnly: true,
                            }}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{
                              width: "100%",
                              backgroundColor: "#D3D3D3",
                            }}
                            fullWidth
                            id="outlined-basic"
                            label="Provider Key"
                            variant="outlined"
                            name="PMSProviderKey"
                            onBlur={this.onBlurOfInputFields.bind(this)}
                            onChange={this.handleChange}
                            inputProps={{ maxLength: 20, readOnly: true }}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            style={{
                              width: "100%",
                              backgroundColor: "#D3D3D3",
                            }}
                            fullWidth
                            id="outlined-basic"
                            label="URL Endpoint"
                            variant="outlined"
                            name="PMSUrlEndpoint"
                            onBlur={this.onBlurOfInputFields.bind(this)}
                            onChange={this.handleChange}
                            inputProps={{ maxLength: 20, readOnly: true }}
                            required
                          />
                        </Grid>
                      </>
                    )}
                    {this.state.inputData.PMSProvider !== "none" && (
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          value={this.state.inputData.PMSInventoryType}
                          style={{
                            width: "100%",
                            backgroundColor:
                              this.state.inputData.ChannelManager ===
                              "guestline"
                                ? "#D3D3D3"
                                : "none",
                          }}
                          fullWidth
                          select
                          label="Inventory Type"
                          variant="outlined"
                          name="PMSInventoryType"
                          onChange={this.handleChange}
                          inputProps={{
                            readOnly:
                              this.state.inputData.ChannelManager ===
                              "guestline"
                                ? true
                                : false,
                          }}
                        >
                          <option value="manual">Manual</option>
                          <option value="live">Live</option>
                        </TextField>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <br />
                <SectionTitle {...{ value: "OTA Insight Settings" }} />
                <br />
                <div style={{ flexGrow: "1" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Autocomplete
                        options={this.state.compsetHotelNameList}
                        getOptionLabel={(option: any) => option.name}
                        id="outlined-basic"
                        //defaultValue={defaultValue}
                        onChange={(event, newHotelNameValue) => {
                          this.setState({
                            newHotelNameValue: newHotelNameValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select OTA Hotel Name"
                            style={{ width: "100%" }}
                            variant="outlined"
                            name="OTAHotelId"
                            onBlur={this.onBlurOfInputFields.bind(this)}
                            error={this.state.validateOTAHotelId}
                            helperText={this.state.validateOTAHotelIdMsg}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </FormControl>
            <div
              style={{
                float: "right",
                marginRight: "2vh",
                marginBottom: "2vh",
              }}
            >
              <Button
                onClick={this.cancelButtonClick}
                color="secondary"
                variant="contained"
                style={{
                  backgroundColor: "#808080",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={this.state.submitBtnStatus}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#ce0e29",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                Create Hotel
              </Button>
            </div>
          </form>

          {this.state.warning3 && (
            <Popup
              isPopupOpen={this.state.warning3}
              title={`${this.state.inputData.HotelName} has been created`}
              content={[
                <p key="popup-content">
                  Please proceed with creating room types & rate plans through
                  the settings page.
                </p>,
              ]}
              action={() => {
                this.setState({
                  displayWarning: false,
                  buttonClickstatus: true,
                });
              }}
              actionName="Back to Dashboard"
              cancelAction={() => {}}
              cancelName="NA"
            />
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}
