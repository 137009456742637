import { stat as gnStat, restrictions as gnRestriction } from "gn-shared";
import { IYieldDay } from "../../../models/yield.models";
import { any } from "prop-types";
import { rowType, Rowprop } from "../rowProp";

export const isLOSRestriction: Function = (name: string) =>
  name === "min_days" || name === "max_days";
export const isNotLOSRestriction: Function = (name: string) =>
  name !== "min_days" && name !== "max_days";
export const aLOSRestrictionIsBeingRemoved: Function = (days: number) => {
  return (
    days === parseInt(gnRestriction.LOS.LOWER_BOUND) ||
    days === parseInt(gnRestriction.LOS.UPPER_BOUND)
  );
};
export const losClass: Function = (name: string) => {
  return isLOSRestriction(name)
    ? name === "min_days"
      ? "minlos-restriction"
      : "maxlos-restriction"
    : "";
};
export const losDays: Function = (days: number) =>
  days && aLOSRestrictionIsBeingRemoved(days) ? "X" : days;

const restrictionDescriptionDict = {
  stop_sell: { title: "Stop sell", symbol: "s", color: true },
  closed_to_arrival: { title: "Closed to arrival", symbol: "a", color: false },
  closed_to_departure: {
    title: "Closed to departure",
    symbol: "d",
    color: false,
  },
  min_days: { title: "Minimum length of stay", symbol: null, color: false },
  max_days: { title: "Maximum length of stay", symbol: null, color: false },
} as { [r: string]: { title: string; symbol: string | null; color: boolean } };

export function gestRateCellContent(
  yieldSheet: any,
  rowProps: Rowprop,
  rowprop: any,
  day: IYieldDay,
  value: any,
  cellProperties: any,
) {
  let restriction: any;
  if (!day.restrictions) {
    return;
  }
  switch (rowprop.type) {
    case "roomType":
      if (!day.restrictions[rowprop.roomTypeId]) {
        return;
      }
      restriction = JSON.parse(
        JSON.stringify(day.restrictions[rowprop.roomTypeId]),
      ).restriction;
      if (restriction) {
        Object.keys(restriction).forEach((key) => {
          let res = restriction[key];
          if (
            !res.active &&
            key != "min_days" &&
            key != "max_days" &&
            res.stat == gnStat.published
          ) {
            delete restriction[key];
          }
        });
      }

      for (let rp in day.restrictions[rowprop.roomTypeId].ratePlans) {
        //filter rp not opened for the displayed range
        if (yieldSheet.outOfRange.has(rp)) {
          continue;
        }
      }
      break;
    case "ratePlan":
      restriction =
        day.restrictions[rowprop.roomTypeId] &&
        day.restrictions[rowprop.roomTypeId].ratePlans &&
        day.restrictions[rowprop.roomTypeId].ratePlans[rowprop.ratePlanId!]
          ? day.restrictions[rowprop.roomTypeId].ratePlans[rowprop.ratePlanId!]
              .restriction
          : null;
      break;
  }

  if (!restriction || Object.keys(restriction).length == 0) {
    return;
  }
  return fillRateCell(
    rowprop,
    rowProps,
    restriction,
    value,
    cellProperties,
    rowprop.isManual,
    day,
  );
}

export function fillRateCell(
  rowprop: any,
  rowProps: Rowprop,
  restriction: any,
  value: any,
  cellProperties: any,
  isManual: boolean,
  day: IYieldDay,
) {
  // Restrictions span set here
  let iscolor = false;
  const restrictionSpans = Object.keys(restriction)
    .filter((name) => restriction[name])
    .map((name) => ({
      ...restrictionDescriptionDict[name],
      name,
      ...restriction[name],
    }))
    .map(({ title, symbol, name, color, stat, active, days }) => {
      /**------------------------Non-LOS Restrictions-------------------------*/
      let span = document.createElement("span");
      span.innerText = symbol;
      if (
        isLOSRestriction(name) &&
        aLOSRestrictionIsBeingRemoved(days) &&
        stat === "published"
      ) {
        return null;
      }
      let losBoundary = gnRestriction.getLOSBoundaries(rowprop.globalRes);
      if (stat === gnStat.edited) {
        var resClass = `restriction restriction-edited `;
        if (isNotLOSRestriction(name)) {
          if (!active) {
            resClass = `restriction restriction-removed `;
          }
        } else {
          if (losBoundary[name].resetValue === days && stat === "published") {
            return null;
          }
          if (aLOSRestrictionIsBeingRemoved(days)) {
            resClass = `restriction restriction-removed `;
          }
        }
        span.className = resClass;
        span.title = title;
        span.innerText = symbol || losDays(days);
        return span;
      }
      if ((days && losBoundary[name].resetValue !== days) || active) {
        if (color) {
          iscolor = true;
          return null;
        }
        span.className = "restriction restriction-published " + losClass(name);
        span.title = title;
        span.innerText = symbol || losDays(days);
        return span;
      } else {
        return null;
      }
    });

  // Set restriction value, dom and styles
  if (iscolor) {
    typeof cellProperties.className === "object"
      ? cellProperties.className.push("inverse")
      : (cellProperties.className += " blackInverse");
  }
  if (isManual) {
    typeof cellProperties.className === "object"
      ? cellProperties.className.push(" manual")
      : (cellProperties.className += " manual");
  }
  if (value && typeof value == "string" && value.indexOf(";") !== -1) {
    let t = value.split(";");
    value = t[0];
  }
  let d = document.createElement("div");
  d.className = !rowProps.isPublishingInventory()
    ? "handsontable htDimmed"
    : cellProperties.className;
  d.innerText = value;
  for (let span of restrictionSpans) {
    if (span) {
      d.appendChild(span);
    }
  }

  return d;
}
