import { Auth } from "aws-amplify";
import { RequireNewPassword as AmplifyRequireNewPassword } from "aws-amplify-react";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { MouseEvent } from "react";
import * as Yup from "yup";
import { AuthState, IAuthData } from "../../models/auth.models";

interface ISignInProps {
  authData: IAuthData;
  authState: AuthState;
  onAuthStateChange: (next: any, data: any) => void;
  onAuthEvent: (next: any, data: any) => void;
}

interface ISignInState {
  pending: boolean;
}

export function resetFormError(
  setErrors: (errors: { [key: string]: string }) => void,
) {
  setErrors({}); // Reset errors to an empty object
}

const RequirePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Please provide your password"),
});
class FormikUtils {
  static getFormikFieldClasses(
    formState: FormikProps<IAuthData>, // Formik Props
    fieldName: keyof IAuthData, // Field name from IAuthData
    size?: "sm" | "lg", // Optional size class
  ): string {
    const baseClass = "form-control";
    const sizeClass = size ? `form-control-${size}` : "";
    const validationClass = !!(
      formState.errors[fieldName] && formState.touched[fieldName]
    )
      ? "is-invalid"
      : "";

    return `${baseClass} ${sizeClass} ${validationClass}`.trim();
  }
}
export class RequireNewPassword extends AmplifyRequireNewPassword<
  ISignInProps,
  ISignInState
> {
  static defaultProps: ISignInProps = {
    authData: {},
    authState: "signIn",
    onAuthStateChange: (next: any, data: any) => null,
    onAuthEvent: (next: any, data: any) => null,
  };

  static state = {
    pending: false,
  };

  _validAuthStates = ["requireNewPassword"];

  changePassword = (
    { password }: IAuthData,
    actions: FormikActions<IAuthData>,
  ) => {
    const user = this.props.authData;

    if (!Auth || typeof Auth.completeNewPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported",
      );
    }
    Auth.completeNewPassword(user, password!, {})
      .then((userResponse: any) => {
        if (userResponse.challengeName === "SMS_MFA") {
          this.changeState("confirmSignIn", userResponse);
        } else if (userResponse.challengeName === "MFA_SETUP") {
          this.changeState("TOTPSetup", userResponse);
        } else {
          this.changeState("signedIn", user);
        }
      })
      .catch((err) => actions.setStatus({ error: err.message || err }));
  };

  onBackToSignIn = (e: MouseEvent) => {
    e.preventDefault();
    this.changeState("signIn", null);
  };

  showComponent() {
    const initialFormValues: IAuthData = {
      password: "",
    };

    return (
      <div className="row mt-4">
        <Formik
          initialValues={initialFormValues}
          onSubmit={this.changePassword}
          validationSchema={RequirePasswordSchema}
        >
          {(formState) => (
            <Form
              onChange={() => resetFormError(formState.setErrors)}
              className="col-sm-4 offset-sm-4 mt-4"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="card">
                <fieldset className="card-body p-5">
                  <h1 className="mb-4">Reset your password</h1>
                  <div className="form-group">
                    <Field
                      type="password"
                      name="password"
                      className={FormikUtils.getFormikFieldClasses(
                        formState,
                        "password",
                        "lg",
                      )}
                      placeholder="Password"
                    />
                    <div className="invalid-feedback">
                      {formState.errors.password}
                    </div>
                  </div>

                  {formState.status && formState.status.error ? (
                    <p className="text-danger">{formState.status.error}</p>
                  ) : (
                    ""
                  )}

                  <button type="submit" className="btn btn-lg btn-primary mt-4">
                    Change password
                  </button>
                  <p className="text-left mt-2">
                    <a href="#" onClick={this.onBackToSignIn}>
                      Back to Sign In
                    </a>
                  </p>
                </fieldset>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
